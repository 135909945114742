// @flow
// FILE REQUESTS
export const LOAD_FILE_REQUESTS = "LOAD_FILE_REQUESTS";
// FILE REQUEST SETTINGS MODAL
export const LOAD_FILE_REQUEST_DATA = "LOAD_FILE_REQUEST_DATA";
export const ADD_FILE_REQUEST = "ADD_FILE_REQUEST";
export const DELETE_FILE_REQUEST = "DELETE_FILE_REQUEST";
export const SET_MESSAGE_SETTINGS = "SET_MESSAGE_SETTINGS";
export const SET_LIMIT_SETTINGS = "SET_LIMIT_SETTINGS";
export const SET_EXPIRES_SETTINGS = "SET_EXPIRES_SETTINGS";
