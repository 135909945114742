//  @flow

export const DEFAULT_MENU_ITEMS = {
  // Default tabs
  MY_PCLOUD: "my_pcloud",
  AUDIO: "audio",
  SHARED: "shared",
  REWIND: "rewind",
  BOOKMARKS: "bookmarks",
  CRYPTO_FOLDER: "cryptofolder",
  CRYPTO_SHARES: "cryptoshares",
  BACKUPS: "backups",
  FILE_REQUEST: "filerequests",
  PHOTO_SEARCH: "photosearch",
  TRASH: "trash",
};

export const PROFILE_MENU_ITEMS = {
  MANAGE_SUBSCRIPTION: "managesubscription",
  INVITE_FRIENDS: "invite_friends",
  FAMILY: "family",
  B_ACCOUNT: "b_account",
  B_BILLING: "b_billing",
  B_USERS: "b_users",
  B_TEAMS: "b_teams",
  B_LOGS: "b_logs",
  SETTINGS: "settings",
  LANGUAGE: "language",
  HELP: 'help',
  LOGOUT: "logout",
  PREV_DESIGN: "previousdesign",
  SPEEDTEST: "speedtest",
};

export const EXPLORE_MENU_ITEMS = {
  PROMO_CARD: "promocard",
  CRYPTO_FOLDER: "cryptofolder",
  BACKUPS: "backups",
  PUBLIC_FOLDER: "publicfolder",
  FILE_REQUEST: "filerequests",
  INVITE_FRIENDS: "invitefriends",
  REWIND: "rewind",
}

export const BOTTOM_ITEMS = {
  DEVICE: "device",
  MORE_APPS: 'more_apps',
  PRICING: 'pricing',
}

export const CUSTOMER_JOURNEY_STEPS = {
  VERIFY_MAIL: "verifymail",
  UPLOAD_FILE: "uploadfile",
  DOWNLOAD_DRIVE: "downloaddrive",
  DOWNLOAD_APP: "downloadapp",
  AUTO_UPLOAD: "autoupload",
  SENT_INVITATION: "sentinvitation",
}

export const EXPLORE_CARDS_TRANSITION_DURATIONS = {
  DEFAULT: 1000,
  SWIPE: 200,
}

export const AUTOSCROLL_INTERVAL = 10000;
export const AUTOSCROLL_PROGRESS_INTERVAL = 100;

export const STORAGE_FULL_RATIO = 0.9;
export const STORAGE_ALMOST_FULL_RATIO = 0.75;

// explore cards in main page - bellow this width only one card is visible
export const ONLY_ONE_CARD_SCREEN_WIDTH = 460;


export const EXPLORE_CARD_MENU_BIG = 240;
export const EXPLORE_CARD_MENU_SMALL = 72;
export const CRYPTO_LOCKED = "cryptolocked";
export const LOCAL_STORAGE_TOGGLED = "isLeftMenuToggled";