// @flow

import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { hiDPI } from "polished";

import { __ } from "../../lib/translate"; // DONE
import { getLocationFromUrl } from "../../lib/utils"; // DONE
import { getLocationApiCall } from "@pcloud/web-utilities/dist/api/methods/getLocationApiCall"; // DONE
import { getApiServerCall, setGlobalLocations } from "@pcloud/web-utilities/dist/api/utils.api"; // DONE
import Dropdown, { DIR_RIGHT, DIR_DOWN } from "../../components/Dropdown"; // DONE
import { Loader } from "../../containers/TwoFactorAuth/SharedComponents"; // DONE
import { LOCATION_TOOLTIP, LOCATION_REGIONS, DEVICE_OS, LOGIN_THEMES } from "@pcloud/web-utilities/dist/config/constants"; // DONE

import type { LocationsType, TooltipType } from "../../types/dataregion"; // DONE

type DropdownItemType = {
  title: string,
  href: boolean | string,
  extra: { id: number }
};

type Props = {
  locations?: LocationsType,
  tooltip?: TooltipType,
  theme?: string
};

const DataCenterDropdown = ({ os, locations = [], tooltip = LOCATION_TOOLTIP, theme = "" }: Props) => {
  const userRegionId = useSelector(({ user }) => user.userRegionId);
  const [locationList, setLocationList] = useState([]);
  const [isInvite, setIsInvite] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [shouldRetry, setShouldRetry] = useState(false);
  const themeStyle = theme && LOGIN_THEMES[theme] ? LOGIN_THEMES[theme] : {};

  const dispatch = useDispatch();

  (locationList: LocationsType);
  (isLoading: boolean);
  (shouldRetry: boolean);

  const getLocation = useCallback(() => {
    setIsLoading(true);
    getLocationApiCall(
      (locations: LocationsType) => {
        if (locations.length) {
          let locationId = locations[0].id;
          setIsLoading(false);
          setLocationList(locations);
          dispatch({ type: "USER_SET_REGION_ID", userRegionId: locationId });
        }
      },
      err => {
        console.log("getLocationApiCall Err>>>>>", err);
        setShouldRetry(true);
        setIsLoading(false);
      }
    );
  }, [dispatch]);

  useEffect(() => {
    if (locations.length) {
      setLocationList(locations);
      setGlobalLocations(locations);
      setIsLoading(false);
      setIsInvite(true);
      dispatch({ type: "USER_SET_REGION_ID", userRegionId: locations[0].id });
    } else {
      getLocation();
    }
  }, [dispatch]);

  const prepareCell = (cell: any, item: DropdownItemType) => {
    const {
      extra: { id }
    } = item;

    cell.css({
      display: "flex",
      "justify-content": "space-between",
      "align-items": "center",
      "padding-right": "15px"
    });

    if (userRegionId === id) {
      cell.append("<div class='check-icon'></div>");
    }

    return cell;
  };

  const getDropDownList = (): DropdownItemType => {
    return locationList.map(location => {
      const { key, text } = LOCATION_REGIONS[location.id];

      return {
        title: __(key, text),
        href: false,
        extra: { id: location.id }
      };
    });
  }

  const getSelectMenuList = (): DropdownItemType => {
    return locationList.map(location => {
      return location.id
    });
  }

  const onItemDropdownClick = (item: DropdownItemType) => {
    const {
      extra: { id }
    } = item;
    const location = locationList.find(el => el.id === id);

    if (location) {
      const locationiId = id;

      dispatch({ type: "USER_SET_REGION_ID", userRegionId: locationiId });
    } else {
      console.log("unhandled", item);
    }
  };

  const rednerLoading = () => {
    return (
      <React.Fragment>
        <LoaderText>{__("data_region", "Data region") + "..."}</LoaderText>
        <LoaderDataCenter />
      </React.Fragment>
    );
  };

  const renderError = () => {
    return (
      <ErrorDataCenter>
        {__("data_region_fail", "	Failed to load data region")}
        <Retry onClick={getLocation}>{__("data_region_retry", "Retry")}</Retry>
      </ErrorDataCenter>
    );
  };

  const renderDropdownWeb = (locationDataList) => {
    const { key = "", text = "" } = LOCATION_REGIONS[userRegionId] || {};
    const isExtension = location.protocol.indexOf('extension') !== -1;

    return (
      <React.Fragment>
        {locationList.length === 1 ? (
          <DropdownWrapper style={themeStyle.dataCenterText}>
            {__("data_region", "Data region") + ":"}
            <SelectedLocation>{__(key, text)}</SelectedLocation>
          </DropdownWrapper>
        ) : (
          <DropdownWrapper style={themeStyle.dataCenterText}>
            {__("data_region", "Data region") + ":"}
            <Dropdown
              direction={DIR_RIGHT}
              verticalDirection={DIR_DOWN}
              childDirection={DIR_RIGHT}
              list={locationDataList}
              onSelect={onItemDropdownClick}
              prepareCell={prepareCell}
              position="absolute"
            >
              <DropdownText style={themeStyle.dataCenterText}>
                <SelectedLocation style={themeStyle.dataCenterText}>{__(key, text)}</SelectedLocation>
                <DropdownArrow style={themeStyle.dataCenterArrow} size={5} direction="bottom" os={os} />
              </DropdownText>
            </Dropdown>
          </DropdownWrapper>
        )}
        <div className="tooltip-icon" data-tooltip-content={__(tooltip.key, tooltip.text)} data-tooltip-id="info">
          {theme == "dark" ? <DarkInfoIcon /> : <LightInfoIcon />}
        </div>
        <ReactTooltip id="info" place={"left"} className="tooltip-container" />
      </React.Fragment>
    );
  };

  const renderDropdown = () => {
    const locationDataList = getDropDownList();

    return renderDropdownWeb(locationDataList);
  }

  if (isLoading) {
    return <Wrapper>{rednerLoading()}</Wrapper>;
  } else if (shouldRetry) {
    return <Wrapper>{renderError()}</Wrapper>;
  } else {
    return <Wrapper>{renderDropdown()}</Wrapper>;
  }
};

export default DataCenterDropdown;

const Wrapper = styled.div`
  display: table;
  width: 100%;
  height: 15px;
  font-size: 13px;
  border-bottom: 1px solid #eeeeee;
  margin-bottom: 15px;
  padding-bottom: 15px;

  .tooltip-icon {
    float: right;
  }

  .tooltip-container {
    padding: 15px 20px;
    max-width: 250px;
    border-radius: 6px;
    font-size: 12px;
    line-height: 1.35;
    font-weight: 400;
    width: 220px;
  }
`;

const DropdownArrow = styled.div`
  width: 7px;
  height: 7px;
  margin-bottom: 5px;
  margin-left: 7px;
  cursor: pointer !important;
  border-right: 1px solid ${({ os, theme }) => os === DEVICE_OS.iOS ? '#17bed0' : theme.textPrimary};
  border-bottom: 1px solid ${({ os, theme }) => os === DEVICE_OS.iOS ? '#17bed0' : theme.textPrimary};
  transform: rotate(45deg);
`;

const DropdownText = styled.div`
  display: flex;
  line-height: 10px;
  align-items: center;
  color: ${({ theme }) => theme.textPrimary};
  cursor: pointer !important;
`;

const SelectedLocation = styled.div`
  display: inline-block;
  margin-left: 5px;
  font-weight: 600;
  color: ${({ theme }) => theme.textPrimary};
  cursor: pointer !important;
`;

const LoaderText = styled.div`
  float: left;
`;

const LoaderDataCenter = styled(Loader)`
  position: relative;
  float: right;
  width: 14px;
  height: 14px;
  top: inherit;
  left: 0;
  right: 0;
  margin: 0;
  border: 2px solid #bbb;
`;

const Retry = styled.div`
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: right;
  color: #17bed0;
  cursor: pointer !important;
`;

const ErrorDataCenter = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: #ff4d4d;
`;

const InfoIcon = styled.div`
  float: right;
  width: 16px;
  height: 16px;
  cursor: pointer !important;

  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;

  background-image: url(${require("../../../root/img/data-location/info.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/data-location/info@2x.png")});
  }
`;

export const DarkInfoIcon = styled(InfoIcon)`
  background-image: url(${require("../../../root/img/data-location/dark_info.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/data-location/dark_info@2x.png")});
  }
`;

export const LightInfoIcon = styled(InfoIcon)`
  background-image: url(${require("../../../root/img/data-location/info.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/data-location/info@2x.png")});
  }
`;

const DropdownWrapper = styled.div`
  float: left;
  color: ${({ theme }) => theme.textPrimary};
`;
