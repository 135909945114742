// @flow

import { userReducer as user } from "./user";
import { contentReducer as content } from "./content";
import { notificationsReducer as notifications } from "./notifications";
import { publicLinkReducer as publiclink } from "./publiclink";
import { sharedFoldersReducer as sharedFolders } from "./sharedFolders";
import { sharedLinksReducer as sharedLinks } from "./sharedLinks";
import { fileRequestsReducer as fileRequests } from "./fileRequests";
import { bookmarksReducer as bookmarks } from "./bookmarks";
import { audioReducer as audio } from "./audio";
import { businessReducer as business } from "./business";
import { audioPlayerReducer as audioPlayer } from "./audioPlayer";
import { onboardingReducer as onboarding } from "./onboarding";
import { galleryPreviewReducer as galleryPreview } from "./galleryPreview";

export default {
  user,
  content,
  notifications,
  publiclink,
  sharedFolders,
  fileRequests,
  bookmarks,
  business,
  sharedLinks,
  audioPlayer,
  onboarding,
  galleryPreview
};
