// @flow

import {
  LOAD_CONTENT,
  DELETE_CONTENT_ITEMS,
  SET_CONTENT_ITEMS,
  ADD_CONTENT_ITEM,
  DELETE_CONTENT_ITEM,
  MODIFY_CONTENT_ITEM,
  SET_CONTENT_KEYS,
  LOAD_BREADCRUMB,
  DELETE_BREADCRUMB,
  SET_LEFT_MENU_CONTENT,
  SET_PAGE_INFO,
  SET_ACTIVE_EXPLORE_CARD,
  INCREMENT_EXPLORE_CARD,
  DECREMENT_EXPLORE_CARD,
  SET_ITEMS_INFO_PANEL_DATA,
  SET_GRID_HEADER_HEIGHT
} from "../constants/content";

export type ContentAction =
  | LoadContent
  | DeleteContentItems
  | SetContentItems
  | AddContentItem
  | DeleteContentItem
  | ModifyContentItem
  | SetContentKeys
  | LoadBreadcrumb
  | SetItemsInfoPanelData
  | SetLeftMenuContent
  | SetPageInfo
  | SetActiveExploreCard

type LoadContent = { type: typeof LOAD_CONTENT, payload: boolean };
type DeleteContentItems = { type: typeof DELETE_CONTENT_ITEMS };
type SetContentItems = { type: typeof ADD_CONTENT_ITEM, metadata: any };
type AddContentItem = { type: typeof ADD_CONTENT_ITEM, metadata: any };
type DeleteContentItem = { type: typeof DELETE_CONTENT_ITEM, metadata: any };
type ModifyContentItem = { type: typeof MODIFY_CONTENT_ITEM, metadata: any };
type SetContentKeys = { type: typeof SET_CONTENT_KEYS, keys: any };
type LoadBreadcrumb = { type: typeof LOAD_BREADCRUMB, metadata: any };
type SetLeftMenuContent = { type: typeof SET_LEFT_MENU_CONTENT, payload: any };
type SetPageInfo = { type: typeof SET_PAGE_INFO, payload: any }
type SetActiveExploreCard = { type: typeof SET_ACTIVE_EXPLORE_CARD, payload: any };
type IncrementExploreCard = { type: typeof INCREMENT_EXPLORE_CARD };
type DecrementExploreCard = { type: typeof DECREMENT_EXPLORE_CARD };
type SetItemsInfoPanelData = { type: typeof SET_ITEMS_INFO_PANEL_DATA, data: any };
type SetGridHeaderHeight = { type: typeof SET_GRID_HEADER_HEIGHT };

export const loadContent = (payload: any): LoadContent => {
  return { type: LOAD_CONTENT, payload };
};

export const setContentItems = (metadata: any): SetContentItems => {
  return { type: SET_CONTENT_ITEMS, payload: metadata };
};

export const addContentItem = (metadata: any): AddContentItem => {
  return { type: ADD_CONTENT_ITEM, payload: metadata };
};

export const deleteContentItem = (metadata: any): DeleteContentItem => {
  return { type: DELETE_CONTENT_ITEM, payload: metadata };
};

export const modifyContentItem = (metadata: any): ModifyContentItem => {
  return { type: MODIFY_CONTENT_ITEM, payload: metadata };
};

export const deleteContentItems = (): DeleteContentItems => {
  return { type: DELETE_CONTENT_ITEMS };
};

export const setContentKeys = (keys: any): SetContentKeys => {
  return { type: SET_CONTENT_KEYS, payload: keys };
};

export const loadBreadcrumb = (metadata): LoadBreadcrumb => {
  return { type: LOAD_BREADCRUMB, payload: metadata };
};

export const deleteBreadcrumb = (): DeleteBreadcrumb => {
  return { type: DELETE_BREADCRUMB };
};

export const setLeftMenuContent = (item): SetLeftMenuContent => {
  return { type: SET_LEFT_MENU_CONTENT, payload: item };
};

export const setPageInfo = (item): SetPageInfo => {
  return { type: SET_PAGE_INFO, payload: item };
};

export const setActiveExploreCard = (item): SetActiveExploreCard => {
  return { type: SET_ACTIVE_EXPLORE_CARD, payload: item };
};

export const incrementActiveExploreCard = (): IncrementExploreCard => {
  return { type: INCREMENT_EXPLORE_CARD };
};

export const decrementActiveExploreCard = (): DecrementExploreCard => {
  return { type: DECREMENT_EXPLORE_CARD };
};

export const setGridHeaderHeight = (height): SetGridHeaderHeight => {
  return { type: SET_GRID_HEADER_HEIGHT, payload: height };
};

export const setItemsInfoPanelData = (data): SetItemsInfoPanelData => {
  return { type: SET_ITEMS_INFO_PANEL_DATA, payload: data };
}

export const contentActionCreators = {
  loadContent,
  deleteContentItems,
  setContentItems,
  addContentItem,
  deleteContentItem,
  modifyContentItem,
  setContentKeys,
  loadBreadcrumb,
  deleteBreadcrumb,
  setLeftMenuContent,
  setPageInfo,
  setActiveExploreCard,
  incrementActiveExploreCard,
  decrementActiveExploreCard,
  setItemsInfoPanelData,
  setGridHeaderHeight,
};
