// @flow

import store from "./store";
import diffs from "../diffs";
import { abortAll } from "../XhrComponentUtils";
import type { Userinfo } from "../../types/user";
import type { UserState } from "./reducers/user";
import {
  setContentItems,
  addContentItem,
  deleteContentItems,
  deleteContentItem,
  modifyContentItem
} from "./actions/content";

let currentToken = "";
let stopListenDiff;
let xhrs = [];

store.subscribe(() => {
  checkUser(currentUserState());
});

checkUser(currentUserState());

function checkUser(userState: UserState) {
  if (userState.token != currentToken) {
    if (currentToken) {
      console.log("stop listen");
      currentToken = "";
      stopListenDiff();
      userLogout();
    }

    if (userState.logged) {
      console.log("go Listen");
      console.log("userState.logged", userState.logged);
      console.log("currentToken", currentToken);
      console.log("userState.token", userState.token);

      currentToken = userState.token;
      listenDiff();
    }
  }
}

function listenDiff() {
  stopListenDiff = diffs.listen(currentToken, events => {
    // createfolder/createfile event
    const createItemEvent = events.filter(
      ev => ev.event === "createfolder" || ev.event === "createfile"
    );
    if (createItemEvent.length) {
      createItemEvent.forEach(({ metadata }) => {
        store.dispatch(addContentItem(metadata));
      });
    }

    // deletefolder/deletefile event
    const deleteItemEvent = events.filter(
      ev => ev.event === "deletefolder" || ev.event === "deletefile"
    );
    if (deleteItemEvent.length) {
      deleteItemEvent.forEach(({ metadata }) => {
        store.dispatch(deleteContentItem(metadata));
      });
    }

    // modifyfolder/modifyfile event
    const modifyFolderEvent = events.filter(
      ev => ev.event === "modifyfolder" || ev.event === "modifyfile"
    );
    if (modifyFolderEvent.length) {
      modifyFolderEvent.forEach(({ metadata }) => {
        store.dispatch(modifyContentItem(metadata));
      });
    }
  });
}

function currentUserinfo(): Userinfo | false {
  const user = currentUserState();
  return user.logged ? user.userinfo : false;
}

function currentUserState(): UserState {
  return store.getState().user;
}

function userLogout() {
  if (xhrs.length) {
    abortAll(xhrs);
  }
  store.dispatch(deleteContentItems());
}
