// @flow

import store from "./store";
import diffs from "../diffs";

import type { Userinfo } from "../../types/user";
import type { UserState } from "./reducers/user";
import type { UserAction } from "./actions/user";
import { modify } from "./actions/user";

let currentToken = "";
let stopListen;

store.subscribe(() => {
  checkUser(currentUserState());
});

checkUser(currentUserState());

function checkUser(userState: UserState) {
  if (userState.token != currentToken) {
    if (currentToken) {
      console.log("stop listen");
      currentToken = "";
      stopListen();
    }

    if (userState.logged) {
      console.log("go Listen");
      console.log("userState.logged", userState.logged);
      console.log("currentToken", currentToken);
      console.log("userState.token", userState.token);

      currentToken = userState.token;
      listen();
    }
  }
}

function listen() {
  stopListen = diffs.listen(currentToken, events => {
    const quota = events.reduce((acc, event) => acc + quotaUpdate(event), 0);
    const userinfo = currentUserinfo();

    // console.log("====================================");
    // console.log("got events", events);
    if (userinfo) {
      console.log(userinfo.usedquota);
      console.log(quota);

      store.dispatch(
        modify({
          ...userinfo,
          usedquota: userinfo.usedquota + quota
        })
      );
    }
    // console.log("====================================");
  });
}

function currentUserinfo(): Userinfo | false {
  const user = currentUserState();
  return user.logged ? user.userinfo : false;
}

function currentUserState(): UserState {
  // console.log("state", store.getState());
  return store.getState().user;
}

// Quota handling

const quotaHandles = {
  deletefile: ({ metadata: { ismine, size } }) => (ismine ? size * -1 : 0),
  createfile: ({ metadata: { ismine, size } }) => (ismine ? size : 0)
};

function quotaUpdate(event) {
  return event.event in quotaHandles ? quotaHandles[event.event](event) : 0;
}
