// @flow
import type { Action } from "../actions/types";
import { LOAD_BOOKMARKS, EDIT_BOOKMARK, DELETE_BOOKMARK } from "../constants/bookmarks";

export type BookmarksState = {
  loaded: boolean,
  bookmarksList: Array<any>
};

const initialState: BookmarksState = {
  loaded: false,
  bookmarksList: []
};

const editBookmarks = (state, data) => {
  const { bookmarksList } = state;
  const editedBookmarks = bookmarksList.map(item => (item.id === data.id ? data : item));
  return { ...editedBookmarks };
};

const deleteBookmark = (state, data) => {
  const { bookmarksList } = state;
  const filteredBookmarks = bookmarksList.filter(({ id }) => id !== data.id);
  return { ...filteredBookmarks };
};

export function bookmarksReducer(
  state: BookmarksState = initialState,
  action: Action
): BookmarksState {
  const { type, payload } = action;

  switch (type) {
    case LOAD_BOOKMARKS:
      return { ...state, loaded: true, bookmarksList: payload };
    case EDIT_BOOKMARK:
      return { ...state, bookmarksList: editBookmarks(state, payload) };
    case DELETE_BOOKMARK:
      return { ...state, bookmarksList: deleteBookmark(state, payload) };
    default:
      return state;
  }
}
