import React from "react";
import styled from "styled-components";

const UserQuotaTooltip = ({ quota }) => {
  return (
    <div>
      {quota.map((section, index) => {
        return (
          <QuotaInfo key={`${index}${section.titleKey}`}>
            <QuotaLegend color={section.color} />
            <QuotaTitle>
              {section.title + " " + section.size}
            </QuotaTitle>
          </QuotaInfo>
        );
      })}
    </div>
  );
};

const QuotaLegend = styled.span`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: ${props => props.color};
  margin-right: 4px;
`

const QuotaInfo = styled.div`
  display: flex;
  align-items: center;
`

const QuotaTitle = styled.span`
  color: var(--color-base-white);
  font-variant-numeric: lining-nums proportional-nums;
  font-family: Roboto;
  font-size: 11px;
  font-weight: 400;
  line-height: 16px;
`

export default UserQuotaTooltip;
