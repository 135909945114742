// @flow
import {
  LOAD_FILE_REQUESTS,
  ADD_FILE_REQUEST,
  DELETE_FILE_REQUEST,
  LOAD_FILE_REQUEST_DATA,
  SET_MESSAGE_SETTINGS,
  SET_LIMIT_SETTINGS,
  SET_EXPIRES_SETTINGS
} from "../constants/fileRequests";
import type { Action } from "../actions/types";

export type FileRequestData = {
  code: string,
  comment: string,
  name: string,
  uploadlinkid: number,
  link: string,
  mail: string,
  linkName: string,
  hasPassword: false,
  expirationDate: false,
  created: string,
  expires: string,
  modified: string,
  maxspace: number,
  metadata: any,
  files: number,
  id: number,
  space: number
};

export type FileRequestSettingsState = {
  loaded: boolean,
  loadedFileRequests: boolean,
  fileRequests: Array<any>,
  linkData: LinkData
};

const initialState: FileRequestSettingsState = {
  loaded: false,
  linkData: {
    code: "",
    comment: "",
    name: "",
    uploadlinkid: 0,
    link: "",
    mail: "",
    linkName: "",
    hasPassword: false,
    expirationDate: false,
    created: "",
    expires: "",
    modified: "",
    maxspace: 0,
    metadata: {},
    files: 0,
    id: 0,
    space: 0
  }
};

const addLink = (state, data) => {
  const { fileRequests } = state;
  fileRequests.push(data);
  return { ...fileRequests };
};

const deleteLink = (state, data) => {
  const { fileRequests } = state;
  const filtredFileRequests = fileRequests.filter(item => item.id !== data.id);
  return { ...filtredFileRequests };
};

export const fileRequestsReducer = (
  state: FileRequestSettingsState = initialState,
  action: Action
): FileRequestSettingsState => {
  const { type, payload } = action;

  switch (type) {
    case LOAD_FILE_REQUESTS:
      return { ...state, loadedFileRequests: true, fileRequests: payload };
    case ADD_FILE_REQUEST:
      return { ...state, fileRequests: addLink(state, payload) };
    case DELETE_FILE_REQUEST:
      return { ...state, fileRequests: deleteLink(state, payload) };
    case LOAD_FILE_REQUEST_DATA:
      return { ...state, loaded: true, linkData: payload };
    case SET_MESSAGE_SETTINGS:
      return { ...state, linkData: { ...state.linkData, comment: payload } };
    case SET_LIMIT_SETTINGS:
      return { ...state, linkData: { ...state.linkData, hasLimit: payload } };
    case SET_EXPIRES_SETTINGS:
      return { ...state, linkData: { ...state.linkData, expires: payload } };
    default:
      return state;
  }
};
