const iconspCloud = {
  "icons": {
    "24": {
      "copy": require("../../root/img/icons/24/copy.png"),
      "dbxn": require("../../root/img/icons/24/dbx.png"),
      "fb": require("../../root/img/icons/24/fb.png"),
      "gdriven": require("../../root/img/icons/24/gdrive.png"),
      "ig": require("../../root/img/icons/24/ig.png"),
      "odn": require("../../root/img/icons/24/od.png"),
      "pi": require("../../root/img/icons/24/pi.png"),

      "deleted": require("../../root/img/icons/24/deleted.png"),
      "playlist": require("../../root/img/icons/24/playlist.png"),
      "virtualfolder": require("../../root/img/icons/24/virtualfolder.png"),
    },

    "24@2x": {
      "copy": require("../../root/img/icons/24@2x/copy.png"),
      "dbxn": require("../../root/img/icons/24@2x/dbx.png"),
      "fb": require("../../root/img/icons/24@2x/fb.png"),
      "gdriven": require("../../root/img/icons/24@2x/gdrive.png"),
      "ig": require("../../root/img/icons/24@2x/ig.png"),
      "odn": require("../../root/img/icons/24@2x/od.png"),
      "pi": require("../../root/img/icons/24@2x/pi.png"),

      "deleted": require("../../root/img/icons/24@2x/deleted.png"),
      "playlist": require("../../root/img/icons/24@2x/playlist.png"),
      "virtualfolder": require("../../root/img/icons/24@2x/virtualfolder.png"),
    }
  },
  "icons-id": {
    "20": {
      0: require("../../root/img/icons-id/20/0.png"),
      1: require("../../root/img/icons-id/20/1.png"),
      2: require("../../root/img/icons-id/20/2.png"),
      3: require("../../root/img/icons-id/20/3.png"),
      4: require("../../root/img/icons-id/20/4.png"),
      5: require("../../root/img/icons-id/20/5.png"),
      20: require("../../root/img/icons-id/20/20.png")
    },
    "20@2x": {
      0: require("../../root/img/icons-id/20@2x/0.png"),
      1: require("../../root/img/icons-id/20@2x/1.png"),
      2: require("../../root/img/icons-id/20@2x/2.png"),
      3: require("../../root/img/icons-id/20@2x/3.png"),
      4: require("../../root/img/icons-id/20@2x/4.png"),
      5: require("../../root/img/icons-id/20@2x/5.png"),
      20: require("../../root/img/icons-id/20@2x/20.png")
    },

    "24": {
      0: require("../../root/img/icons-id/24/0.png"),
      1: require("../../root/img/icons-id/24/1.png"),
      2: require("../../root/img/icons-id/24/2.png"),
      3: require("../../root/img/icons-id/24/3.png"),
      4: require("../../root/img/icons-id/24/4.png"),
      5: require("../../root/img/icons-id/24/5.png"),
      6: require("../../root/img/icons-id/24/6.png"),
      7: require("../../root/img/icons-id/24/7.png"),
      8: require("../../root/img/icons-id/24/8.png"),
      9: require("../../root/img/icons-id/24/9.png"),
      10: require("../../root/img/icons-id/24/10.png"),
      11: require("../../root/img/icons-id/24/11.png"),
      12: require("../../root/img/icons-id/24/12.png"),
      13: require("../../root/img/icons-id/24/13.png"),
      14: require("../../root/img/icons-id/24/14.png"),
      15: require("../../root/img/icons-id/24/15.png"),
      16: require("../../root/img/icons-id/24/16.png"),
      17: require("../../root/img/icons-id/24/17.png"),
      18: require("../../root/img/icons-id/24/18.png"),
      19: require("../../root/img/icons-id/24/19.png"),
      20: require("../../root/img/icons-id/24/20.png"),
      21: require("../../root/img/icons-id/24/21.png"),
      22: require("../../root/img/icons-id/24/22.png"),
      23: require("../../root/img/icons-id/24/23.png"),
      24: require("../../root/img/icons-id/24/24.png"),
      26: require("../../root/img/icons-id/24/26.png"),
      27: require("../../root/img/icons-id/24/27.png"),
      28: require("../../root/img/icons-id/24/28.png"),
      29: require("../../root/img/icons-id/24/29.png"),
      30: require("../../root/img/icons-id/24/30.png"),

      1000: require("../../root/img/icons-id/24/1000.png"),
      1001: require("../../root/img/icons-id/24/1001.png"),
      1002: require("../../root/img/icons-id/24/1002.png"),
      1002: require("../../root/img/icons-id/24/1002.png"),
      1003: require("../../root/img/icons-id/24/1003.png"),
      1006: require("../../root/img/icons-id/24/1006.png"),
      1007: require("../../root/img/icons-id/24/1007.png"),
      1008: require("../../root/img/icons-id/24/1008.png"),
      1009: require("../../root/img/icons-id/24/1009.png"),
      1010: require("../../root/img/icons-id/24/1010.png"),

      deleted: require("../../root/img/icons-id/24/deleted.png"),
      "folder-linux": require("../../root/img/icons-id/24/folder-linux.png"),
      "folder-mac": require("../../root/img/icons-id/24/folder-mac.png")
    },

    "24@2x": {
      0: require("../../root/img/icons-id/24@2x/0.png"),
      1: require("../../root/img/icons-id/24@2x/1.png"),
      2: require("../../root/img/icons-id/24@2x/2.png"),
      3: require("../../root/img/icons-id/24@2x/3.png"),
      4: require("../../root/img/icons-id/24@2x/4.png"),
      5: require("../../root/img/icons-id/24@2x/5.png"),
      6: require("../../root/img/icons-id/24@2x/6.png"),
      7: require("../../root/img/icons-id/24@2x/7.png"),
      8: require("../../root/img/icons-id/24@2x/8.png"),
      9: require("../../root/img/icons-id/24@2x/9.png"),
      10: require("../../root/img/icons-id/24@2x/10.png"),
      11: require("../../root/img/icons-id/24@2x/11.png"),
      12: require("../../root/img/icons-id/24@2x/12.png"),
      13: require("../../root/img/icons-id/24@2x/13.png"),
      14: require("../../root/img/icons-id/24@2x/14.png"),
      15: require("../../root/img/icons-id/24@2x/15.png"),
      16: require("../../root/img/icons-id/24@2x/16.png"),
      17: require("../../root/img/icons-id/24@2x/17.png"),
      18: require("../../root/img/icons-id/24@2x/18.png"),
      19: require("../../root/img/icons-id/24@2x/19.png"),
      20: require("../../root/img/icons-id/24@2x/20.png"),
      21: require("../../root/img/icons-id/24@2x/21.png"),
      22: require("../../root/img/icons-id/24@2x/22.png"),
      23: require("../../root/img/icons-id/24@2x/23.png"),
      24: require("../../root/img/icons-id/24@2x/24.png"),
      26: require("../../root/img/icons-id/24@2x/26.png"),
      27: require("../../root/img/icons-id/24@2x/27.png"),
      28: require("../../root/img/icons-id/24@2x/28.png"),
      29: require("../../root/img/icons-id/24@2x/29.png"),
      30: require("../../root/img/icons-id/24@2x/30.png"),

      1000: require("../../root/img/icons-id/24@2x/1000.png"),
      1001: require("../../root/img/icons-id/24@2x/1001.png"),
      1002: require("../../root/img/icons-id/24@2x/1002.png"),
      1003: require("../../root/img/icons-id/24@2x/1003.png"),
      1006: require("../../root/img/icons-id/24@2x/1006.png"),
      1007: require("../../root/img/icons-id/24@2x/1007.png"),
      1008: require("../../root/img/icons-id/24@2x/1008.png"),
      1009: require("../../root/img/icons-id/24@2x/1009.png"),
      1010: require("../../root/img/icons-id/24@2x/1010.png"),

      "folder-linux": require("../../root/img/icons-id/24@2x/folder-linux.png"),
      "folder-mac": require("../../root/img/icons-id/24@2x/folder-mac.png")
    },

    "32": {
      0: require("../../root/img/icons-id/32/0.png"),
      1: require("../../root/img/icons-id/32/1.png"),
      2: require("../../root/img/icons-id/32/2.png"),
      3: require("../../root/img/icons-id/32/3.png"),
      4: require("../../root/img/icons-id/32/4.png"),
      5: require("../../root/img/icons-id/32/5.png"),
      6: require("../../root/img/icons-id/32/6.png"),
      7: require("../../root/img/icons-id/32/7.png"),
      8: require("../../root/img/icons-id/32/8.png"),
      9: require("../../root/img/icons-id/32/9.png"),
      10: require("../../root/img/icons-id/32/10.png"),
      11: require("../../root/img/icons-id/32/11.png"),
      12: require("../../root/img/icons-id/32/12.png"),
      13: require("../../root/img/icons-id/32/13.png"),
      14: require("../../root/img/icons-id/32/14.png"),
      15: require("../../root/img/icons-id/32/15.png"),
      16: require("../../root/img/icons-id/32/16.png"),
      17: require("../../root/img/icons-id/32/17.png"),
      18: require("../../root/img/icons-id/32/18.png"),
      19: require("../../root/img/icons-id/32/19.png"),
      20: require("../../root/img/icons-id/32/20.png"),
      21: require("../../root/img/icons-id/32/21.png"),
      22: require("../../root/img/icons-id/32/22.png"),
      23: require("../../root/img/icons-id/32/23.png"),
      24: require("../../root/img/icons-id/32/24.png"),
      25: require("../../root/img/icons-id/32/25.png"),
      26: require("../../root/img/icons-id/32/26.png"),
      27: require("../../root/img/icons-id/32/27.png"),

      1006: require("../../root/img/icons-id/32/1006.png"),

      "folder-linux": require("../../root/img/icons-id/32/folder-linux.png"),
      "folder-mac": require("../../root/img/icons-id/32/folder-mac.png"),
      playlist: require("../../root/img/icons-id/32/playlist.png")
    },

    "32@2x": {
      0: require("../../root/img/icons-id/32@2x/0.png"),
      1: require("../../root/img/icons-id/32@2x/1.png"),
      2: require("../../root/img/icons-id/32@2x/2.png"),
      3: require("../../root/img/icons-id/32@2x/3.png"),
      4: require("../../root/img/icons-id/32@2x/4.png"),
      5: require("../../root/img/icons-id/32@2x/5.png"),
      6: require("../../root/img/icons-id/32@2x/6.png"),
      7: require("../../root/img/icons-id/32@2x/7.png"),
      8: require("../../root/img/icons-id/32@2x/8.png"),
      9: require("../../root/img/icons-id/32@2x/9.png"),
      10: require("../../root/img/icons-id/32@2x/10.png"),
      11: require("../../root/img/icons-id/32@2x/11.png"),
      12: require("../../root/img/icons-id/32@2x/12.png"),
      13: require("../../root/img/icons-id/32@2x/13.png"),
      14: require("../../root/img/icons-id/32@2x/14.png"),
      15: require("../../root/img/icons-id/32@2x/15.png"),
      16: require("../../root/img/icons-id/32@2x/16.png"),
      17: require("../../root/img/icons-id/32@2x/17.png"),
      18: require("../../root/img/icons-id/32@2x/18.png"),
      19: require("../../root/img/icons-id/32@2x/19.png"),
      20: require("../../root/img/icons-id/32@2x/20.png"),
      21: require("../../root/img/icons-id/32@2x/21.png"),
      22: require("../../root/img/icons-id/32@2x/22.png"),
      23: require("../../root/img/icons-id/32@2x/23.png"),
      24: require("../../root/img/icons-id/32@2x/24.png"),
      25: require("../../root/img/icons-id/32@2x/25.png"),
      26: require("../../root/img/icons-id/32@2x/26.png"),
      27: require("../../root/img/icons-id/32@2x/27.png"),

      1006: require("../../root/img/icons-id/32@2x/1006.png"),

      "folder-linux": require("../../root/img/icons-id/32@2x/folder-linux.png"),
      "folder-mac": require("../../root/img/icons-id/32@2x/folder-mac.png"),
      playlist: require("../../root/img/icons-id/32@2x/playlist.png")
    },

    "48": {
      0: require("../../root/img/icons-id/48/0.png"),
      1: require("../../root/img/icons-id/48/1.png"),
      2: require("../../root/img/icons-id/48/2.png"),
      3: require("../../root/img/icons-id/48/3.png"),
      4: require("../../root/img/icons-id/48/4.png"),
      5: require("../../root/img/icons-id/48/5.png"),
      6: require("../../root/img/icons-id/48/6.png"),
      7: require("../../root/img/icons-id/48/7.png"),
      8: require("../../root/img/icons-id/48/8.png"),
      9: require("../../root/img/icons-id/48/9.png"),
      10: require("../../root/img/icons-id/48/10.png"),
      11: require("../../root/img/icons-id/48/11.png"),
      12: require("../../root/img/icons-id/48/12.png"),
      13: require("../../root/img/icons-id/48/13.png"),
      14: require("../../root/img/icons-id/48/14.png"),
      15: require("../../root/img/icons-id/48/15.png"),
      16: require("../../root/img/icons-id/48/16.png"),
      17: require("../../root/img/icons-id/48/17.png"),
      18: require("../../root/img/icons-id/48/18.png"),
      19: require("../../root/img/icons-id/48/19.png"),
      20: require("../../root/img/icons-id/48/20.png"),
      21: require("../../root/img/icons-id/48/21.png"),
      22: require("../../root/img/icons-id/48/22.png"),
      23: require("../../root/img/icons-id/48/23.png"),
      24: require("../../root/img/icons-id/48/24.png"),
      28: require("../../root/img/icons-id/48/28.png"),
      29: require("../../root/img/icons-id/48/29.png"),
      30: require("../../root/img/icons-id/48/30.png"),

      1006: require("../../root/img/icons-id/48/1006.png"),
      1009: require("../../root/img/icons-id/48/1009.png"),
      1010: require("../../root/img/icons-id/48/1010.png"),

      "folder-linux": require("../../root/img/icons-id/48/folder-linux.png"),
      "folder-mac": require("../../root/img/icons-id/48/folder-mac.png"),
      playlist: require("../../root/img/icons-id/48/playlist.png")
    },

    "48@2x": {
      0: require("../../root/img/icons-id/48@2x/0.png"),
      1: require("../../root/img/icons-id/48@2x/1.png"),
      2: require("../../root/img/icons-id/48@2x/2.png"),
      3: require("../../root/img/icons-id/48@2x/3.png"),
      4: require("../../root/img/icons-id/48@2x/4.png"),
      5: require("../../root/img/icons-id/48@2x/5.png"),
      6: require("../../root/img/icons-id/48@2x/6.png"),
      7: require("../../root/img/icons-id/48@2x/7.png"),
      8: require("../../root/img/icons-id/48@2x/8.png"),
      9: require("../../root/img/icons-id/48@2x/9.png"),
      10: require("../../root/img/icons-id/48@2x/10.png"),
      11: require("../../root/img/icons-id/48@2x/11.png"),
      12: require("../../root/img/icons-id/48@2x/12.png"),
      13: require("../../root/img/icons-id/48@2x/13.png"),
      14: require("../../root/img/icons-id/48@2x/14.png"),
      15: require("../../root/img/icons-id/48@2x/15.png"),
      16: require("../../root/img/icons-id/48@2x/16.png"),
      17: require("../../root/img/icons-id/48@2x/17.png"),
      18: require("../../root/img/icons-id/48@2x/18.png"),
      19: require("../../root/img/icons-id/48@2x/19.png"),
      20: require("../../root/img/icons-id/48@2x/20.png"),
      21: require("../../root/img/icons-id/48@2x/21.png"),
      22: require("../../root/img/icons-id/48@2x/22.png"),
      23: require("../../root/img/icons-id/48@2x/23.png"),
      24: require("../../root/img/icons-id/48@2x/24.png"),
      28: require("../../root/img/icons-id/48@2x/28.png"),
      29: require("../../root/img/icons-id/48@2x/29.png"),
      30: require("../../root/img/icons-id/48@2x/30.png"),

      1006: require("../../root/img/icons-id/48@2x/1006.png"),
      1009: require("../../root/img/icons-id/48@2x/1009.png"),
      1010: require("../../root/img/icons-id/48@2x/1010.png"),

      "folder-linux": require("../../root/img/icons-id/48@2x/folder-linux.png"),
      "folder-mac": require("../../root/img/icons-id/48@2x/folder-mac.png"),
      playlist: require("../../root/img/icons-id/48@2x/playlist.png")
    },

    "120": {
      0: require("../../root/img/icons-id/120/0.png"),
      1: require("../../root/img/icons-id/120/1.png"),
      2: require("../../root/img/icons-id/120/2.png"),
      3: require("../../root/img/icons-id/120/3.png"),
      4: require("../../root/img/icons-id/120/4.png"),
      5: require("../../root/img/icons-id/120/5.png"),
      6: require("../../root/img/icons-id/120/6.png"),
      7: require("../../root/img/icons-id/120/7.png"),
      8: require("../../root/img/icons-id/120/8.png"),
      9: require("../../root/img/icons-id/120/9.png"),
      10: require("../../root/img/icons-id/120/10.png"),
      11: require("../../root/img/icons-id/120/11.png"),
      12: require("../../root/img/icons-id/120/12.png"),
      13: require("../../root/img/icons-id/120/13.png"),
      14: require("../../root/img/icons-id/120/14.png"),
      15: require("../../root/img/icons-id/120/15.png"),
      16: require("../../root/img/icons-id/120/16.png"),
      17: require("../../root/img/icons-id/120/17.png"),
      18: require("../../root/img/icons-id/120/18.png"),
      19: require("../../root/img/icons-id/120/19.png"),
      20: require("../../root/img/icons-id/120/20.png"),
      21: require("../../root/img/icons-id/120/21.png"),
      22: require("../../root/img/icons-id/120/22.png"),
      23: require("../../root/img/icons-id/120/23.png"),

      1006: require("../../root/img/icons-id/120/1006.png"),

      "folder-linux": require("../../root/img/icons-id/120/folder-linux.png"),
      "folder-mac": require("../../root/img/icons-id/120/folder-mac.png"),
      playlist: require("../../root/img/icons-id/120/playlist.png")
    },

    "120@2x": {
      0: require("../../root/img/icons-id/120@2x/0.png"),
      1: require("../../root/img/icons-id/120@2x/1.png"),
      2: require("../../root/img/icons-id/120@2x/2.png"),
      3: require("../../root/img/icons-id/120@2x/3.png"),
      4: require("../../root/img/icons-id/120@2x/4.png"),
      5: require("../../root/img/icons-id/120@2x/5.png"),
      6: require("../../root/img/icons-id/120@2x/6.png"),
      7: require("../../root/img/icons-id/120@2x/7.png"),
      8: require("../../root/img/icons-id/120@2x/8.png"),
      9: require("../../root/img/icons-id/120@2x/9.png"),
      10: require("../../root/img/icons-id/120@2x/10.png"),
      11: require("../../root/img/icons-id/120@2x/11.png"),
      12: require("../../root/img/icons-id/120@2x/12.png"),
      13: require("../../root/img/icons-id/120@2x/13.png"),
      14: require("../../root/img/icons-id/120@2x/14.png"),
      15: require("../../root/img/icons-id/120@2x/15.png"),
      16: require("../../root/img/icons-id/120@2x/16.png"),
      17: require("../../root/img/icons-id/120@2x/17.png"),
      18: require("../../root/img/icons-id/120@2x/18.png"),
      19: require("../../root/img/icons-id/120@2x/19.png"),
      20: require("../../root/img/icons-id/120@2x/20.png"),
      21: require("../../root/img/icons-id/120@2x/21.png"),
      22: require("../../root/img/icons-id/120@2x/22.png"),
      23: require("../../root/img/icons-id/120@2x/23.png"),

      1006: require("../../root/img/icons-id/120@2x/1006.png"),

      "folder-linux": require("../../root/img/icons-id/120@2x/folder-linux.png"),
      "folder-mac": require("../../root/img/icons-id/120@2x/folder-mac.png"),
      playlist: require("../../root/img/icons-id/120@2x/playlist.png")
    },

    "svg": {
      0: require("../../root/img/icons-id/svg/0.svg"),
      1: require("../../root/img/icons-id/svg/1.svg"),
      2: require("../../root/img/icons-id/svg/2.svg"),
      3: require("../../root/img/icons-id/svg/3.svg"),
      4: require("../../root/img/icons-id/svg/4.svg"),
      5: require("../../root/img/icons-id/svg/5.svg"),
      6: require("../../root/img/icons-id/svg/6.svg"),
      7: require("../../root/img/icons-id/svg/7.svg"),
      8: require("../../root/img/icons-id/svg/8.svg"),
      9: require("../../root/img/icons-id/svg/9.svg"),
      10: require("../../root/img/icons-id/svg/10.svg"),
      11: require("../../root/img/icons-id/svg/11.svg"),
      12: require("../../root/img/icons-id/svg/12.svg"),
      13: require("../../root/img/icons-id/svg/13.svg"),
      14: require("../../root/img/icons-id/svg/14.svg"),
      15: require("../../root/img/icons-id/svg/15.svg"),
      16: require("../../root/img/icons-id/svg/16.svg"),
      17: require("../../root/img/icons-id/svg/17.svg"),
      18: require("../../root/img/icons-id/svg/18.svg"),
      19: require("../../root/img/icons-id/svg/19.svg"),
      20: require("../../root/img/icons-id/svg/20.svg"),
      21: require("../../root/img/icons-id/svg/21.svg"),
      22: require("../../root/img/icons-id/svg/22.svg"),
      23: require("../../root/img/icons-id/svg/23.svg"),
      24: require("../../root/img/icons-id/svg/24.svg"),
      27: require("../../root/img/icons-id/svg/27.svg"), // Bookmarks
      28: require("../../root/img/icons-id/svg/28.svg"),
      29: require("../../root/img/icons-id/svg/29.svg"),
      30: require("../../root/img/icons-id/svg/30.svg"),

      1000: require("../../root/img/icons-id/svg/1000.svg"),
      1001: require("../../root/img/icons-id/svg/1001.svg"),
      1002: require("../../root/img/icons-id/svg/1002.svg"),
      1003: require("../../root/img/icons-id/svg/1003.svg"),
      1006: require("../../root/img/icons-id/svg/1006.svg"),
      1009: require("../../root/img/icons-id/svg/1009.svg"),
      1010: require("../../root/img/icons-id/svg/1010.svg"),

      2701: require("../../root/img/icons-id/svg/2701.svg"), // Bookmarks
      2702: require("../../root/img/icons-id/svg/2702.svg"), // Bookmarks
      2703: require("../../root/img/icons-id/svg/2703.svg"), // Bookmarks
      2704: require("../../root/img/icons-id/svg/2704.svg"), // Bookmarks

      3001: require("../../root/img/icons-id/svg/3001.svg"), // Artist
      3002: require("../../root/img/icons-id/svg/3002.svg"), // Album
      3003: require("../../root/img/icons-id/svg/3003.svg"), // Playlist

      "folder-linux": require("../../root/img/icons-id/svg/folder-linux.svg"),
      "folder-mac": require("../../root/img/icons-id/svg/folder-mac.svg"),
      playlist: require("../../root/img/icons-id/svg/playlist.svg"),
    },

    "svg-darker": {
      0: require("../../root/img/icons-id/svg-darker/0.svg"),
      1: require("../../root/img/icons-id/svg-darker/1.svg"),
      2: require("../../root/img/icons-id/svg-darker/2.svg"),
      3: require("../../root/img/icons-id/svg-darker/3.svg"),
      4: require("../../root/img/icons-id/svg-darker/4.svg"),
      5: require("../../root/img/icons-id/svg-darker/5.svg"),
      6: require("../../root/img/icons-id/svg-darker/6.svg"),
      7: require("../../root/img/icons-id/svg-darker/7.svg"),
      8: require("../../root/img/icons-id/svg-darker/8.svg"),
      9: require("../../root/img/icons-id/svg-darker/9.svg"),
      10: require("../../root/img/icons-id/svg-darker/10.svg"),
      11: require("../../root/img/icons-id/svg-darker/11.svg"),
      12: require("../../root/img/icons-id/svg-darker/12.svg"),
      13: require("../../root/img/icons-id/svg-darker/13.svg"),
      14: require("../../root/img/icons-id/svg-darker/14.svg"),
      15: require("../../root/img/icons-id/svg-darker/15.svg"),
      16: require("../../root/img/icons-id/svg-darker/16.svg"),
      17: require("../../root/img/icons-id/svg-darker/17.svg"),
      18: require("../../root/img/icons-id/svg-darker/18.svg"),
      19: require("../../root/img/icons-id/svg-darker/19.svg"),
      20: require("../../root/img/icons-id/svg-darker/20.svg"),
      21: require("../../root/img/icons-id/svg-darker/21.svg"),
      22: require("../../root/img/icons-id/svg-darker/22.svg"),
      23: require("../../root/img/icons-id/svg-darker/23.svg"),
      24: require("../../root/img/icons-id/svg-darker/24.svg"),
      28: require("../../root/img/icons-id/svg-darker/28.svg"),
      29: require("../../root/img/icons-id/svg-darker/29.svg"),
      30: require("../../root/img/icons-id/svg-darker/30.svg"),

      1000: require("../../root/img/icons-id/svg-darker/1000.svg"),
      1001: require("../../root/img/icons-id/svg-darker/1001.svg"),
      1002: require("../../root/img/icons-id/svg-darker/1002.svg"),
      1003: require("../../root/img/icons-id/svg-darker/1003.svg"),
      1006: require("../../root/img/icons-id/svg-darker/1006.svg"),
      1009: require("../../root/img/icons-id/svg-darker/1009.svg"),
      1010: require("../../root/img/icons-id/svg-darker/1010.svg"),

      "folder-linux": require("../../root/img/icons-id/svg-darker/folder-linux.svg"),
      "folder-mac": require("../../root/img/icons-id/svg-darker/folder-mac.svg"),
      playlist: require("../../root/img/icons-id/svg-darker/playlist.svg"),
    },

  }
};

export default iconspCloud;
