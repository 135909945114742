// @flow
// Shared links
export const LOAD_SHARED_LINKS = "LOAD_SHARED_LINKS";
export const ADD_SHARED_LINK = "ADD_SHARED_LINK";
export const DELETE_SHARED_LINK = "DELETE_SHARED_LINK";
// Shared link settings
export const LOAD_LINK_DATA = "LOAD_LINK_DATA";
export const SET_UPLOAD_SETTINGS = "SET_UPLOAD_SETTINGS";
export const SET_DOWNLOAD_SETTING = "SET_DOWNLOAD_SETTING";
export const SET_HAS_PASSWORD = "SET_HAS_PASSWORD";
export const SET_EXPIRES = "SET_EXPIRES";
export const SET_EMAILS_WITH_UPLOAD_ACCESS = "SET_EMAILS_WITH_UPLOAD_ACCESS";
export const SET_SHORT_LINK_URL = "SET_SHORT_LINK_URL";
