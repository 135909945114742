/*
OPTIONS:
Each "content option" represents one of the options listed above the content when user selects a single or multiple items. The same options are also presented to the user in the context menu when right click is triggered. 

SECTIONS:
Each "content option" is categorized in different sections (structured as arrays of option ids), which represents the separation in the context menu. They are written at the same order as they should appear in the UI.
*/

// Predefined content option ids below:
export const PREVIEW: "preview" = "preview";
export const PREVIEW_PUBLIC: "previewPublic" = "previewPublic";
export const OPEN: "open" = "open";
export const OPENDOC: "opendoc" = "opendoc";
export const EXTRACT: "extract" = "extract";
export const COMMENT: "comment" = "comment";
export const SHOW_COMMENTS: "showComments" = "showComments";
export const PLAY: "play" = "play";
export const PLAY_SELECTED: "playSelected" = "playSelected";
export const ADD_TO_PLAYER: "addToPlayer" = "addToPlayer";
export const ADD_TO_PLAYLIST: "addToPlayList" = "addToPlayList";
export const SHARE_LINK: "shareLink" = "shareLink";
export const INVITE_TO_FOLDER: "inviteToFolder" = "inviteToFolder";
export const REQUEST_FILES: "requestFiles" = "requestFiles";
export const STOP_BACKUP: "stopBackup" = "stopBackup";
export const GET_LINK: "getLink" = "getLink";
export const DOWNLOAD: "download" = "download";
export const DOWNLOAD_RESIZED: "downloadResized" = "downloadResized";
export const DOWNLOAD_CONVERTED: "downloadConverted" = "downloadConverted";
export const DOWNLOAD_ARCHIVE: "downloadArchive" = "downloadArchive";
export const RESTORE: "restore" = "restore";
export const DELETE_FOREVER: "deleteForever" = "deleteForever";
export const EMPTY_TRASH: "emptyTrash" = "emptyTrash";
export const DELETE: "delete" = "delete";
export const DELETE_LINK: "deleteLink" = "deleteLink";
export const MOVE: "move" = "move";
export const MOVE_PUBLIC: "movePublic" = "movePublic";
export const COPY: "copy" = "copy";
export const COPY_LINK: "copyLink" = "copyLink";
export const LINK_SETTINGS: "linkSettings" = "linkSettings";
export const RENAME: "rename" = "rename";
export const FILE_INFO: "fileInfo" = "fileInfo";
export const OPEN_LOCATION: "openLocation" = "openLocation";
export const FOLDER_INFO: "folderInfo" = "folderInfo";
export const REVISIONS: "revisions" = "revisions";
export const REVERT_REV: "revertrev" = "revertrev";
export const RESTORE_REV: "restorerev" = "restorerev";
export const RESTORE_TIME: "restoretime" = "restoretime";
export const DOWNLOAD_TIME: "downloadtime" = "downloadtime";
export const EDIT_BOOKMARK: "editBookmark" = "editBookmark";
export const REMOVE_BOOKMARK: "removeBookmark" = "removeBookmark";
export const EXPORT_BOOKMARK: "exportBookmark" = "exportBookmark";
export const DEACTIVATE_PUBLIC_FOLDER: "deactivatePublicFolder" = "deactivatePublicFolder";

// Predefined option section ids below:
export const FILE_SECTION: "fileSection" = "fileSection";
export const SHARE_SECTION: "shareSection" = "shareSection";
export const ARCHIVE_SECTION: "archiveSection" = "archiveSection";
export const DOWNLOAD_SECTION: "downloadSection" = "downloadSection";
export const DELETE_SECTION: "deleteSection" = "deleteSection";
export const EDIT_SECTION: "editSection" = "editSection";
export const INFO_SECTION: "infoSection" = "infoSection";

// Predefined subsection ids below:
export const SHARE_SUBSECTION: "shareSubsection" = "shareSubsection";
export const ADDTO_SUBSECTION: "addtoSubsection" = "addtoSubsection";

export const SELECTION_OPTIONS_SECTIONS = {
  [FILE_SECTION]: { order: 1, options: [PREVIEW, OPEN, EXTRACT, PLAY, PLAY_SELECTED, COMMENT, SHOW_COMMENTS] },
  [ADDTO_SUBSECTION]: { order: 2, options: [ADD_TO_PLAYER, ADD_TO_PLAYLIST] },
  [SHARE_SECTION]: { order: 3, options: [GET_LINK, DEACTIVATE_PUBLIC_FOLDER, SHARE_LINK, REQUEST_FILES] },
  [SHARE_SUBSECTION]: { order: 4, options: [SHARE_LINK, INVITE_TO_FOLDER] },
  [DOWNLOAD_SECTION]: { order: 5, options: [DOWNLOAD, DOWNLOAD_RESIZED, DOWNLOAD_CONVERTED] },
  [ARCHIVE_SECTION]: { order: 6, options: [DOWNLOAD_ARCHIVE] },
  [EDIT_SECTION]: { order: 7, options: [DELETE, MOVE, COPY, RENAME, EDIT_BOOKMARK, EXPORT_BOOKMARK, REMOVE_BOOKMARK] },
  [INFO_SECTION]: { order: 8, options: [FILE_INFO, FOLDER_INFO, REVISIONS] },
  // [DELETE_SECTION]: { order: 7, options: [] }
};

// Predefined content option ids below:
export const GRID_VIEW: "gridView" = "gridView";
export const GRID_ASPECT_RATIO_VIEW: "gridAspectRatioView" = "gridAspectRatioView";
export const LIST_VIEW: "listView" = "listView";
export const LARGE_LIST_VIEW: "largeListView" = "largeListView";
export const SORT: "sort" = "sort";
export const SORT_ASCENDING: "sortAscending" = "sortAscending";
export const SORT_DESCENDING: "sortDescending" = "sortDescending";
export const SORT_NEWEST_TOP: "sortNewestTop" = "sortNewestTop";
export const SORT_OLDEST_TOP: "sortOldestTop" = "sortOldestTop";
export const SORT_FOLDERS_FIRST: "sortFoldersFirst" = "sortFoldersFirst";

// Predefined content ids below:
export const BROWSE_CONTENT: "browse" = "browse";
export const BROWSE_PHOTOSEARCH_RESULTS: "browse_photosearch" = "browse_photosearch";
export const BROWSE_DELETED_CONTENT: "browse_deleted_content" = "browse_deleted_content";
export const BROWSE_SONGS: "browse_songs" = "browse_songs";
export const BROWSE_ARTIST: "browse_artist" = "browse_artist";
export const BROWSE_ARTISTS: "browse_artists" = "browse_artists";
export const BROWSE_ALBUM: "browse_album" = "browse_album";
export const BROWSE_ALBUMS: "browse_albums" = "browse_albums";
export const BROWSE_PLAYLISTS: "browse_playlists" = "browse_playlists";
export const BROWSE_PLAYLIST: "browse_playlist" = "browse_playlist";
export const BROWSE_CONTENT_REWIND: "browse_content_rewind" = "browse_content_rewind";
export const BROWSE_BOOKMARKS: "browse_bookmarks" = "browse_bookmarks";
export const BROWSE_FILEREQUESTS: "browse_filerequests" = "browse_filerequests";

// Predefined column ids below:
export const SELECT: "select" = "select";
export const NAME: "name" = "name";
export const TITLE: "title" = "title";
export const DESCRIPTION: "description" = "description";
export const SIZE: "size" = "size";
export const CREATED: "created" = "created";
export const MODIFIED: "modified" = "modified";
export const DELETED: "deleted" = "deleted";
export const TYPE_ICON: "typeIcon" = "typeIcon";
export const NAME_SUB: "nameSub" = "nameSub";
export const ACTIONS_COLUMN: "actionsColumn" = "actionsColumn";
export const ARTIST: "artist" = "artist";
export const ALBUM: "album" = "album";
export const GENRE: "genre" = "genre";
export const ALBUMS_COUNT: "albums_count" = "albums_count";
export const SONGS_COUNT: "songs_count" = "songs_count";
export const ITEMS: "items" = "items";

// Predefined content action buttons ids below:
export const COMMENTS_BUTTON: "commentsButton" = "commentsButton";
export const INVITE_BUTTON: "inviteButton" = "inviteButton";
export const DIRECT_LINK_SHARE_MENU_BUTTON: "directLinkShareMenuButton" = "directLinkShareMenuButton";
export const STOP_BACKUP_BUTTON: "stopBackupButton" = "stopBackupButton";
export const SHARE_MENU_BUTTON: "shareMenuButton" = "shareMenuButton";
export const OPEN_DOCUMENT_BUTTON: "openDocumentButton" = "openDocumentButton";

// Default sorting option.
export const SORT_DIRECTION_ASC: "sortAsc" = "sortAsc";
export const SORT_DIRECTION_DESC: "sortDesc" = "sortDesc";
export const SORT_NAME_ASC: "sortNameAsc" = "sortNameAsc";
export const SORT_NAME_DESC: "sortNameDesc" = "sortNameDesc";
export const SORT_DATE_ASC: "sortDateAsc" = "sortDateAsc";
export const SORT_DATE_DESC: "sortDateDesc" = "sortDateDesc";

export const MOBILE_WIDTH_BREAKPOINT = 1024;

// Items info panel
// Temporary disable ItemsInfo Information tab side panel until it's ready. -> switch to "info"
export const DEFAULT_SELECTED_ITEMS_INFO_TAB = "comments";
