// @flow
import { LOAD_BOOKMARKS, EDIT_BOOKMARK, DELETE_BOOKMARK } from "../constants/bookmarks";

export type BookmarksAction = LoadBookmarksAction | EditBookmarkAction | DeleteBookmarkAction;

type LoadBookmarksAction = { type: typeof LOAD_BOOKMARKS, payload: Array<any> };
type EditBookmarkAction = { type: typeof EDIT_BOOKMARK, payload: any };
type DeleteBookmarkAction = { type: typeof DELETE_BOOKMARK, payload: any };

export const loadBookmarks = (list: Array<any>): LoadBookmarksAction => {
  return { type: LOAD_BOOKMARKS, payload: list };
};

export const editBookmark = (data: any): EditBookmarkAction => {
  return { type: EDIT_BOOKMARK, payload: data };
};

export const deleteBookmark = (data: any): DeleteBookmarkAction => {
  return { type: DELETE_BOOKMARK, payload: data };
};

export const bookmarksActionCreators = {
  loadBookmarks,
  editBookmark,
  deleteBookmark
};
