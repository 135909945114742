import { __ } from './translate';
const errors = {
  //create playlist
  1039: __("error_1039", "Please enter playlist name."),
  // tfa 
  2074: __("tfa_login_error_2074", "Expired security code"),
  3012: __("tfa_setup_error_3012", "Can not deliver SMS to provided MSISDN - permanent error."),
  4007: __("tfa_setup_error_4007", "SMS can not be send. Try again later."),
  4000: __("tfa_login_error_4000", "Too many login tries from this IP address."),

  2012: __("Wrong code"),
  2003: __("error_2003", "Access denied. You do not have permissions to perform this operation."),
  2255: __("error_2255", "You reached the limit of this action. Try again later."),
  2272: __("Incorrect password"),
  2274: __("You have active subscription"),

  2032: __("Password too short. Minimum length is 6 characters."),
  2034: __("Password does not contain enough different characters.The minimum is 4."),
  2035: __("Password can not contain only consecutive characters."),
  2036: __("error_2036_expired_code"),
  2245: __("error_2245", "Maximum message length is 500 characters."),
  2240: __("error_2240", "Message can't contain URL."),
  2307: __("error_2307", "Invite request already exists."),
  2310: __("error_2310", "Not enough space available."),
  2311: __("error_2311", "This user is not a member of family plan."),
  2076: __("error_2076", "You do not have the permissions for this action."),
  2333: __("error_2333", "You have recently moved this account. It's not possible to move it again so soon."),

  // copyfolder, copypublink
  2346: __("error_2346", "You cannot add files/folders to this backup folder."),
  2206: __("error_2206", "Can not copy folder into itself."),

  // deletefolder
  2345: __("error_2345", "There are active backups for this folder."),

  // sharefolder
  2338: __("error_2338", "Backup folders can't be shared with other users."),
  2350: __("error_2350", "This folder contains too many folders/files and cannot be shared with other pCloud users all at once. Try sharing individual subfolders."),

  // createuploadlink, getfolderpublink, publink/createfolderlinkandsend
  2339: __("error_2339", "You can't share backup folders."),

  // getfilepublink
  "2343_getfilepublink": __("error_2343_getfilepublink", "This file is part of a backup and can't be shared with a link."),
  // renamefile
  "2343_renamefile": __("error_2343_renamefile", "This file is shared with a link and can't be moved to a backup folder."),
  // renamefolder
  "2343_renamefolder": __("error_2343_renamefolder", "This folder is shared with a link and can't be moved to a backup folder."),

  // gettreepublink
  2280: __("error_2280", "You can only share the public folder using a direct link."),

  // createfolder, createfolderifnotexists
  2344: __("error_2344", "You cannot add folders to this backup folder."),

  // acceptshare, renamefolder
  2341: __("error_2341", "Shared folders can't be placed inside backup folders."),
  2351: __("error_2351", "You cannot accept the folder because it contains too many subfolders or files."),

  // renamefolder
  2340: __("error_2340", "Moving is not allowed for this folder."),
  2342: __("error_2342", "Folders with file requests can't be placed inside backup folders."),
  2043: __("error_2043"),
  2005: __("error_2005"),

  2282: __("error_2282"),
  2284: __("error_2284"),
  2285: __("error_2285"),
  2283: __("error_2283"),

  2001: __("error_2001"),
  2023: __("error_2023"),
  2008: __("error_2008"),
  2004: __("error_2004"),
  2020: __("error_2020"),
  2021: __("error_2021"),
  2022: __("error_2022"),

  2002: __("error_2002"),
  2024: __("error_2024"),
  2045: __("error_2045"),
  2075: __("error_2075"),
  2009: __("error_2009"),
  2059: __('error_2059', 'Unsupported archive format.'),
  3006: __('error_3006', 'Extracting of the archive failed.'),
  7009: __('error_7009', 'The archive is password-protected.'),
  2352: __("error_2352", "This folder contains too many subfolders or files to be moved to a shared folder."),
  2208: __("error_2208", "The destination folder no longer exists."),
  2207: __("error_2207", "Cannot copy folder to subfolder of itself."),
  2040: __("error_2040", "The link is invalid or is already deleted"),
  1064: __("Enter valid e-mail address."),
  2018: __("This is not a valid contact or an email."),
  2320: __("error_2320", "This user is not a member of the Business account."),
  2353: __("error_2353", "You don't have enough available storage in your account to downgrade the Business plan."),
  2337: __("error_2337", "You cannot invite users to the Business account, because you have an active relocation request."),

  //removepublinkbranding
  2027: __("error_2027", "The link is invalid or is already deleted"),

  //playlist
  2073: __("The name for the collection is already used.", "The name for the collection is already used."),

  2000: __("tfa_login_error_2000", "Log in failed."),
  2041: __("error_2041", "Connection broken."),
  "link-not-accessible": __('This URL link is not accessible. Please, choose another download link.', 'This URL link is not accessible. Please, choose another download link.'),
  4001: __("Monthly remote downloads limit reached.", "Monthly remote downloads limit reached."),
  2124: __("error_2124", "Crypto subscription expired."),
};

export const errorKeys = {
  1039: "error_1039",
  2074: "tfa_login_error_2074",
  3012: "tfa_setup_error_3012",
  4007: "tfa_setup_error_4007",
  4000: "tfa_login_error_4000",

  2012: "Wrong code",
  2003: "error_2003",
  2255: "error_2255",
  2272: "Incorrect password",
  2274: "You have active subscription",
  1064: "Enter valid e-mail address.",
  2018: "This is not a valid contact or an email.",
  
  2032: "Password too short. Minimum length is 6 characters.",
  2034: "Password does not contain enough different characters.The minimum is 4.",
  2035: "Password can not contain only consecutive characters.",
  2036: "error_2036_expired_code",
  2245: "error_2245",
  2240: "error_2240",
  2307: "error_2307",
  2310: "error_2310",
  2311: "error_2311",
  2076: "error_2076",
  2333: "error_2333",
  // copyfolder, copypublink
  2346: "error_2346",
  2206: "error_2206",
  // deletefolder
  2345: "error_2345",
  // sharefolder
  2338: "error_2338",
  2350: "error_2350",
  // createuploadlink, getfolderpublink, publink/createfolderlinkandsend
  2339: "error_2339",
  "2343_getfilepublink": "error_2343_getfilepublink",
  "2343_renamefile": "error_2343_renamefile",
  "2343_renamefolder": "error_2343_renamefolder",
  // gettreepublink
  2280: "error_2280",
  // gettreepublink
  2344: "error_2344",
  // acceptshare, renamefolder
  2341: "error_2341",
  2351: "error_2351",
  // renamefolder
  2340: "error_2340",
  2342: "error_2342",
  2043: "error_2043",
  2005: "error_2005",

  2282: "error_2282",
  2284: "error_2284",
  2285: "error_2285",
  2283: "error_2283",

  2001: "error_2001",
  2023: "error_2023",
  2008: "error_2008",
  2004: "error_2004",
  2020: "error_2020",
  2021: "error_2021",
  2022: "error_2022",

  2002: "error_2002",
  2024: "error_2024",
  "2024_acceptshare": "error_2024_acceptshare",
  2045: "error_2045",
  2075: "error_2075",
  2009: "error_2009",

  2059: 'error_2059',
  3006: 'error_3006',
  7009: 'error_7009',
  2352: "error_2352",
  2340: "error_2340",
  2208: "error_2208",
  2207: "error_2207",
  2040: "error_2040",
  2320: "error_2320",
  2353: "error_2353",
  2337: "error_2337",
  2027: "error_2027",

  //playlist
  2073: "The name for the collection is already used.",

  2000: "tfa_login_error_2000",
  2041: "error_2041",
  "link-not-accessible": 'This URL link is not accessible. Please, choose another download link.',
  4001: __("Monthly remote downloads limit reached.", "Monthly remote downloads limit reached."),
  2124: __("error_2124", "Crypto subscription expired."),
};

export default errors;

// FAMILY ERRORS

// fm_sendinvitation
// 2003: "Access denied. You do not have permissions to perform this operation.",
// 2245: "Maximum message length is 500 characters.",
// 2240: "Message can't contain URL.",
// 2312
// 1064: "Please provide list of valid 'mails'.",
// 1183: "Please provide 'quota'.",
// 1184: "Invalid 'quota' provided.",
// 2255: "You reached the limit of this action. Try again later.",
// 5000
// 2307: "Invite request already exists.",
// 2310: "Not enough space available.",
// 1033: "Please provide 'mail'."

// fm_changequota
// 2003: "Access denied. You do not have permissions to perform this operation.",
// 1043: "Please provide 'userid'.",
// 1183: "Please provide 'quota'.",
// 1184: "Invalid 'quota' provided.",
// 2048: "User not found.",
// 2311: "This user is not a member of family plan.",
// 2310: "Not enough space available.",

// fm_exitfromaccount
// 2076: "You do not have the permissions for this action.",
// 2048: "User not found.",
// 5000
// 2311: "This user is not a member of family plan.",

// fm_removemember
// 2003: "Access denied. You do not have permissions to perform this operation.",
// 1043: "Please provide 'userid'.",
// 2048: "User not found.",
// 5000
// 2311: "This user is not a member of family plan.",


// fm_removemember
// 2003: "Access denied. You do not have permissions to perform this operation.",
// 1043: "Please provide 'userid'.",
// 2048: "User not found.",
// 5000
// 2311: "This user is not a member of family plan.",

// fm_resendinvitation
// 2003: "Access denied. You do not have permissions to perform this operation.",
// 1185: "Please provide 'invitationid'.",
// 1186: "Invalid 'invitationid' provided.",
// 2255: "You reached the limit of this action. Try again later."


// tfa_deactivate
// tfa_getrecoverycodes
// tfa_resetrecoverycodes
// tfa_verifyactivationcode
// sendsetpasswordlink
// usersettingsinfo
// getip - no err

// tfa_sendactivationcode
//  1034: "Please provide ‘password’.",
//  2272: "Wrong ‘password’ provided.",
//  1106:"Please provide ‘msisdn’.",
//  1170: "Please provide ‘countrycode’.",
// 3012: __("tfa_setup_error_3012", "Can not deliver SMS to provided MSISDN - permanent error."),
// 4007: __("tfa_setup_error_4007", "SMS can not be send. Try again later.")

// lostpassword
// 1000: "Log in required".
// 1033	"Please provide 'mail'".
// 2000	"Log in failed".
// 4000	"Too many login tries from this IP address".

// userinfo
// 1000: "Log in required".
// 1033:	"Please provide 'mail'".
// 2000:	"Log in failed".
// 4000:	"Too many login tries from this IP address".


// resetpassword
// 1000	Log in required.+
// 1022	Please provide 'code'.
// 1032	Please provide 'newpassword'.
// 2000	Log in failed.+
// 2012	Invalid 'code' provided.
// 2032	Password too short. Minimum length is 6 characters.+
// 2033	Password can not start or end with space.
// 2034	Password does not contain enough different characters. The minimum is 4.
// 2035	Password can not contain only consecutive characters.
// 2036	Verification 'code' expired. Please request password reset again.
// 4000	Too many login tries from this IP address.+
// 5000	Internal error. Try again later.+

// 1002 = "No full path or folderid provided." // wrong params
// 2042 = "Cannot rename the root folder." // rename folder error
// 2005 = "Directory does not exist." //wrong params
// 1037 = "Please provide at least one of 'topath', 'tofolderid' or 'toname'." // wrong params
// 2341 = "Backup folders can't contain shared folders. // general
// 2282 = "Permissions denied. Public folder can't contain shared folder." // general
// 2343 = "Backup folders can't contain download links."
// 2284 = "Permissions denied. Public folder can't contain download link."
// 2285 = "Permissions denied. Shared folder can't contain public folder.",
// 2342 = "Backup folders can't contain upload links."
// 2283 = "Permissions denied. Public folder can't contain upload link.",
// 2200 = "Can not move between encrypted and non-encrypted folders." // general
// 2340 = "You can't move this folder outside its parent folder." // item
// 2346 = "You can't place this item in backup folders." // general
// 2201 = "Need to provide 'toname' when moving to encrypted folder.", //wrong params --- something went wrong
// 2001 = "Invalid file/folder name.", // wrong input rename action, show general
// 2043 = "Cannot move a folder to a subfolder of itself.", // general
// 2023 = "You are trying to place shared folder into another shared folder.", // general
// 2008 = "User is over quota.", // general close modal
// 2202 = "Can not move encrypted folder to a shared folder", // general
// 2003 = "Access denied. You do not have permissions to perform this operation.", // general close modal
// 5000 = "Internal error. Try again later.", // dont show
// 2004 = "File or folder already exists.", // show already exist flow

// savezip

// 1000	Log in required.
// 1016	No full topath or toname/tofolderid provided.
// 1017	Invalid 'folderid' provided.
// 1018	Invalid 'fileid' provided.
// 2000	Log in failed.
// 2001	Invalid file/folder name.
// 2002	A component of parent directory does not exist.
// 2003	Access denied. You do not have permissions to preform this operation.
// 2004	File or folder alredy exists.
// 2008	User is over quota.
// 2009	File not found.
// 4000	Too many login tries from this IP address.
// 5000	Internal error. Try again later.
// 5001	Internal upload error.

// copyfolder
// 2346  "You can't place this item in backup folders."	You cannot add files/folders to this backup folder.	error_2346	 
// 2206	"Can not copy folder into itself"	Can not copy folder into itself!	error_2206	 
// 2008	"User is over quota.",	You don't have enough free space in your account to perform this action.	error_2008	 
// 2003	"Access denied. You do not have permissions to perform this operation."	Access denied. You do not have permissions to perform this operation	error_2003	 
// 5000	"Internal error. Try again later.",	 	 	 
// 2001	"Invalid file/folder name.",	Invalid name provided	error_2001	 
// 2002	"A component of parent directory does not exist."	 	 	 
// 2119	"Can not create non-encrypted file in encrypted folder."	 	 	 
// 2004	"File or folder already exists.",	File or folder already exists	error_2004
