// @flow

import {
  LOAD_SHARED,
  UNLOAD_SHARED,
  LOAD_SHARED_FOLDERS,
  LOAD_FOLDER_SHARES,
  CLEAR_FOLDER_SHARES,
  GET_SHARED_ITEMS,
  ADD_SHARE_TO_FOLDER,
  DELETE_SHARE_TO_FOLDER,
  UPDATE_SHARE_TO_FOLDER,
  ACCEPTED_SHARE_TO_FOLDER
} from "../constants/sharedFolders";

export type SharedFoldersAction =
  | LoadSharedAction
  | UnloadSharesAction
  | ListSharesAction
  | LoadFolderSharesAction
  | ClearFolderSharesAction
  | AddShareToFolderAction
  | DeleteShareToFolderAction;

type ListShares = {
  shares: Array<any>,
  requests: Array<any>
};

type LoadSharedAction = { type: typeof LOAD_SHARED };
type UnloadSharedAction = { type: typeof UNLOAD_SHARED };
type LoadSharedFoldersAction = { type: typeof LOAD_SHARED_FOLDERS, payload: ListShares };
type GetSharedItemsAction = { type: typeof GET_SHARED_ITEMS, payload: number };
type LoadFolderSharesAction = { type: typeof LOAD_FOLDER_SHARES, payload: any };
type ClearFolderSharesAction = { type: typeof CLEAR_FOLDER_SHARES };
type AddShareToFolderAction = { type: typeof ADD_SHARE_TO_FOLDER, payload: any };
type DeleteShareToFolderAction = {
  type: typeof DELETE_SHARE_TO_FOLDER,
  share: any
};
type UpdateShareToFolder = {
  type: typeof UPDATE_SHARE_TO_FOLDER,
  share: any
};
type AcceptedShareToFolder = {
  type: typeof ACCEPTED_SHARE_TO_FOLDER,
  share: any
};

export const loadShared = (): LoadSharedAction => {
  return { type: LOAD_SHARED };
};

export const unloadShared = (): UnloadSharedAction => {
  return { type: UNLOAD_SHARED };
};

export const loadSharedFolders = (folders: ListShares): LoadSharedFoldersAction => {
  return { type: LOAD_SHARED_FOLDERS, payload: folders };
};

export const getSharedItems = (items: number): GetSharedItemsAction => {
  return { type: GET_SHARED_ITEMS, payload: items };
};

export const loadFolderShares = (folders: any): LoadFolderSharesAction => {
  return { type: LOAD_FOLDER_SHARES, payload: folders };
};
export const clearFolderShares = (): ClearFolderSharesAction => {
  return { type: CLEAR_FOLDER_SHARES };
};

export const addShareToFolder = (share: any): AddShareToFolderAction => {
  return { type: ADD_SHARE_TO_FOLDER, payload: share };
};

export const deleteShareToFolder = (share: any): DeleteShareToFolderAction => {
  return { type: DELETE_SHARE_TO_FOLDER, payload: share };
};

export const updateShareToFolder = (share: any): UpdateShareToFolder => {
  return { type: UPDATE_SHARE_TO_FOLDER, payload: share };
};

export const acceptedShareToFolder = (share: any): AcceptedShareToFolder => {
  return { type: ACCEPTED_SHARE_TO_FOLDER, payload: share };
};

export const sharedFoldersActionCreators = {
  loadSharedFolders,
  unloadShared,
  getSharedItems,
  loadFolderShares,
  clearFolderShares,
  addShareToFolder,
  deleteShareToFolder,
  updateShareToFolder,
  acceptedShareToFolder
};
