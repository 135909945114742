// @flow

import React from "react";
import styled from "styled-components";

type Props = {
  backgroundColor?: string,
  separatorWidth?: string,
  separatorHeight?: string,
  borderRadius?: string,
  right?: number,
};

const Separator = (props: Props) => (
  <Wrap $separatorHeight={props.separatorHeight} $backgroundColor={props.backgroundColor} $separatorWidth={props.separatorWidth} $borderRadius={props.borderRadius}></Wrap>
);

export default Separator;

const Wrap = styled.div`
  float: left;
  display: inline-block;
  height: ${props => props.$separatorHeight ? props.$separatorHeight : "1px"};
  background-color: ${props => props.backgroundColor ? props.backgroundColor : "#fff"};
  width: ${props => props.separatorWidth ? props.separatorWidth : "1px"};
  position: relative;
  border-radius: ${props => props.borderRadius ? props.borderRadius : "0px"};
  right: ${props => props.right ? props.right : "0"}px;
`;