import PropTypes from "prop-types";
import React, { Component } from "react";
import styled from "styled-components";
import { hiDPI } from "polished";
import { userInitials } from "../lib/user-utils";

export const AvatarWrap = styled.div`
  display: inline-block;
  width: ${({size}) => size ? size + "px" : "32px"};
  height: ${({size}) => size ? size + "px" : "32px"};
  line-height: ${({size}) => size ? (size + 1) + "px" : "33px"};
  text-align: center;
  border-radius: 100%;
  cursor: pointer;
  background: var(--color-primary-700);

  font-family: "Roboto", sans-serif;
  color: var(--color-base-white);
  font-size: ${({textSize}) => textSize || "13px"};
  text-transform: uppercase;
  vertical-align: middle;
  
`;

const LoadingImg = styled.div`
  background-image: url(${require("../../root/img/header/loading.gif")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../root/img/header/loading@2x.gif")});
  }
  background-size: 20px 20px;
  background-repeat: no-repeat;
  background-position: center center;
  height: 32px;
  width: 32px;
`;

const UsedQuotaWarning = styled(AvatarWrap)`
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 32px 32px;
  background-image: url(${require("../../root/img/header/so.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../root/img/header/so@2x.png")});
  }
`;

const UserAvatar = ({ userinfo, loading, europeUI }) => {
  const {usedquota, quota} = userinfo;
  const percent = Math.min(100, Math.round((usedquota / quota) * 100));
  if (percent >= 100) {
    return (
      <UsedQuotaWarning>{loading ? <LoadingImg /> : null}</UsedQuotaWarning>
    );
  }
  return (
    <AvatarWrap europeUI={europeUI}>{loading ? <LoadingImg /> : userInitials(userinfo)}</AvatarWrap>
  );
};

export default UserAvatar;
