// @flow

import subscriptions from "../../lib/subscriptions";
import { ICONS, iconTypes } from "../icons";
import apiMethod from "../../api/apiMethod";
import type { Notification } from "./types";
import { enrichNotificationsWithUrl, notificationUrl } from "./utils";

let authToken = false;
let isRunning = false;
let lastNotificaionId = 0;
let readNotificationId = 0;
let notificationsList: Array<Notification> = [];
let listeners = [];

// used for both pollins and reading
let readXhr = null;

const notificationsInstance = {
  handleEvents: receiveNotifications,
  getParams: () => {
    return {
      notificationid: lastNotificaionId,
      notificationthumbsize: iconTypes[ICONS.SVG_LIST].thumb.size,
      auth: authToken
    };
  }
};

function receiveNotifications({
  notificationid,
  notifications
}: {
  notificationid: string,
  notifications: Array<Notification>
}) {
  console.log("New notification: ", notifications);

  lastNotificaionId = notificationid;
  notificationsList = notifications;

  handleUpdate();
  subscriptions.setState("notifications", "ready");
}

function handleUpdate() {
  console.log("send event to subscribers");
  listeners.forEach(listener => listener(enrichNotificationsWithUrl(notificationsList)));
}

function poll(ready) {
  readXhr = apiMethod(
    "listnotifications",
    { auth: authToken, notificationthumbsize: iconTypes[ICONS.SVG_LIST].thumb.size },
    ret => {
      receiveNotifications(ret);

      readXhr = false;
      ready && ready();
    }, { errorCallback: () => {} }
  );
}

function listen(token: string, callback: (Array<Notification>) => void) {
  if (authToken !== token) {
    authToken = token;
  }

  listeners.push(callback);

  if (!isRunning) {
    start();
  }

  return () => {
    listeners.splice(listeners.indexOf(callback));

    if (!listeners.length) {
      stop();
    }
  };
}

function start() {
  if (isRunning) {
    stop();
  }

  isRunning = true;
  subscriptions.add("notifications", notificationsInstance);

  poll();
  //setTimeout(poll, 2000);
  //poll();
}

function stop() {
  lastNotificaionId = 0;
  readNotificationId = 0;
  notificationsList = [];

  if (readXhr) {
    readXhr.abort();
  }

  isRunning = false;
  subscriptions.remove("notifications");
}

export function readNotifications(notificationid: number) {
  if (readXhr) {
    readXhr.abort();
  }

  readXhr = apiMethod(
    "readnotifications",
    { notificationid: notificationid, auth: authToken },
    function() {
      console.log("notications read");
      readXhr = null;
    }
  );

  /*
    that.readNotificationId = that.lastNotificaionId;
    that.notifications.forEach(function markAsRead(notify) { notify.isnew = false; });
    that.handleUpdate(that.notifications);
    console.log('notications read');
  });
  */
}

export default { listen, readNotifications };
