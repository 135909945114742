import PropTypes from "prop-types";
import React, { Component } from "react";
import { createRoot } from "react-dom/client";
import $ from "jquery";
import popOver from "../lib/popOver";

export const DIR_LEFT = "left";
export const DIR_RIGHT = "right";
export const DIR_UP = "up";
export const DIR_DOWN = "down";

export default class Popover extends Component {
    static propTypes = {
        children: PropTypes.any.isRequired,
        render: PropTypes.element.isRequired,
        cssPosition: PropTypes.oneOf(["fixed", "absolute"]),
        position: PropTypes.oneOf(["left", "right", "top", "bottom", "center"]),
        align: PropTypes.oneOf(["left", "right", "top", "bottom"]),
        valign: PropTypes.oneOf(["left", "right", "top", "bottom", "center"]),
        trigger: PropTypes.oneOf(["click", "mouseover"]),
        onOpen: PropTypes.func,
        onClose: PropTypes.func,
        mobileOpen: PropTypes.bool,
        flyin: PropTypes.oneOf(["left", "right", "top", "bottom"]),
    };

    static defaultProps = {
        trigger: "click",
        cssPosition: "fixed",
        position: "bottom",
        align: "left",
        valign: "top",
        onOpen: () => {},
        onClose: () => {},
        mobileOpen: false,
        flyin: "top",
    };

    constructor(props) {
        super(props);

        this.popOverObject = $("<div></div>")
            .appendTo(document.body)
            .hide();
        this.root = createRoot(this.popOverObject[0]);
    }

    componentDidMount() {
        const { position, align, valign, trigger, onOpen, onClose, mobileOpen, flyin, cssPosition } = this.props;

        this.root.render(this.props.render);

        this.detachPopover = popOver.attach({
            el: $(this.ref),
            pos: position,
            align,
            valign,
            obj: this.popOverObject,
            trigger,
            onOpen,
            onClose,
            mobileOpen,
            genClass: "popover-nothing",
            appendTo: "body",
            flyin,
            cssPosition,
        });
    }

    componentDidUpdate() {
        this.root.render(this.props.render);
    }

    componentWillUnmount() {
        if (this.detachPopover) {
            this.detachPopover();
        }

        this.root.unmount();
        this.popOverObject.remove();
    }

    render() {
        return (
            <div className="popover-holder" ref={(ref) => (this.ref = ref)}>
                {this.props.children}
            </div>
        );
    }
}
