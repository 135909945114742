import apiMethodMy from "./apiMethodMy";

import { setcookie } from "../lib/utils";

import Popup from "../lib/Popup";

import { default as apiConfig, EU_API, US_API, DEFAULT_LOCATIONS } from "@pcloud/web-utilities/dist/api/config.api";
import hashManager from "../lib/hashManager";

export { EU_API, US_API, DEFAULT_LOCATIONS }

apiConfig.errorCallback = function (data) {
  console.log(HFN.LOGIN_EXPIRED_CODES);
  console.log(data.result);

  if (HFN.ERROR_MESSAGE[data.result] != undefined) {
    Popup.close();
    HFN.message(__e(data), 'error');
  } else if (data.result.toString().charAt(0) == 3) {
    console.log('Ignored Error', data);
  } else if (HFN.LOGIN_EXPIRED_CODES.indexOf(data.result) !== -1) {
    Popup.close();
    HFN.message("Session Expired. Please login.", 'error');
    setcookie('pcauth', '', -1);
    setcookie('locationid', '', -1);
    hashManager.pushState({ page: 'login' }, 2);
    return;
  } else {
    console.log('Uncatched Error: ');
    console.log(data);
    // HFN.message(data.error, 'error');
  }
};

apiConfig.onXhrError = function (xhr, status, error, apiCall) {
  // manually aborted calls are fine
  if (status === "abort") {
    return;
  }

  console.trace();
  console.log('api defaults', apiConfig);
  console.log('rejected ', arguments);

  let currentServer = apiCall.opts.apiServer;
  const paymentsApi = ['qabraintree.pcloud.com', "eqapayments.pcloud.com", "payments.pcloud.com", "epayments.pcloud.com"];

  if (paymentsApi.indexOf(currentServer) === -1 && currentServer != apiConfig.defaultApiServer) {
    apiConfig.apiServer = currentServer = apiConfig.defaultApiServer;

    console.log('retrying', apiCall);

    // retry with new api
    apiMethodMy(apiCall.method, apiCall.params, apiCall.callback, apiCall.opts);
  }
}

export default apiConfig;
