// @flow

import type { FileRequestData } from "../reducers/fileRequests";
import {
  LOAD_FILE_REQUESTS,
  ADD_FILE_REQUEST,
  DELETE_FILE_REQUEST,
  LOAD_FILE_REQUEST_DATA,
  SET_MESSAGE_SETTINGS,
  SET_LIMIT_SETTINGS,
  SET_EXPIRES_SETTINGS
} from "../constants/fileRequests";

export type FileRequestsAction =
  | LoadFileRequests
  | AddFileRequest
  | DeleteFileRequest
  | LoadFileRequestData
  | SetMessageSettings
  | SetLimitSettings
  | SetExpiresSettings;

type LoadFileRequests = { type: typeof LOAD_FILE_REQUESTS, payload: Array<any> };
type AddFileRequest = { type: typeof ADD_FILE_REQUEST, payload: data };
type DeleteFileRequest = { type: typeof DELETE_FILE_REQUEST, payload: data };
type LoadFileRequestData = { type: typeof LOAD_FILE_REQUEST_DATA, payload: FileRequestData };
type SetMessageSettings = { type: typeof SET_MESSAGE_SETTINGS, payload: string };
type SetLimitSettings = { type: typeof SET_LIMIT_SETTINGS, payload: number };
type SetExpiresSettings = { type: typeof SET_EXPIRES_SETTINGS, payload: string };

export const loadFileRequests = (list: Array<any>): LoadFileRequests => {
  return { type: LOAD_FILE_REQUESTS, payload: list };
};

export const addFileRequest = (data: any): AddFileRequest => {
  return { type: ADD_FILE_REQUEST, payload: data };
};

export const deleteFileRequest = (data: any): DeleteFileRequest => {
  return { type: DELETE_FILE_REQUEST, payload: data };
};

export const loadFileRequestData = (data: FileRequestData): LoadFileRequestData => {
  return { type: LOAD_FILE_REQUEST_DATA, payload: data };
};

export const setMessageSettings = (comment: string): SetMessageSettings => {
  return { type: SET_MESSAGE_SETTINGS, payload: comment };
};

export const setLimitSettings = (maxspace: string): SetLimitSettings => {
  return { type: SET_LIMIT_SETTINGS, payload: maxspace };
};

export const setExpiresSettings = (expires: boolean): SetExpiresSettings => {
  return { type: SET_EXPIRES_SETTINGS, payload: expires };
};

export const fileRequestsActionCreators = {
  loadFileRequests,
  addFileRequest,
  deleteFileRequest,
  loadFileRequestData,
  setMessageSettings,
  setLimitSettings,
  setExpiresSettings
};
