import styled, { keyframes } from "styled-components";
import { hiDPI } from "polished";

export const BackButton = styled.div`
  position: absolute;
  top: 20px;
  left: -22px;
  padding-left: 52px;
  margin-right: 8px;
  font-size: 13px;
  color: #17bed0;
  cursor: pointer;

  background-size: 9px 7px;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(${require("../../../root/img/2fa-login/back.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/2fa-login/back@2x.png")});
  }
`;

export const Subheading = styled.div`
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 12px;
  text-align: left;
`;

export const SuccessMessage = styled.div`
  font-size: 15px;
  padding: 12px 40px;
  border-radius: 5px;
  border: solid 1px #fcf2d6;
  background-color: #fff8e1;
`;

export const ButtonWrapper = styled.div`
  display: flex;
`;

export const Container = styled.div`
  width: 480px;
  border-radius: 21px;
  background-color: #fff;
  font-size: 18px;
  font-weight: bold;
  padding: 30px;
  box-sizing: border-box;
  transition: all 250ms ease-in-out;

  &.invite-folder-modal {
    position: relative;
    width: 600px;
    min-height: 190px;
    
    @media (max-width: 800px) {
      width: 100% ;
      min-height: 260px;
    }
  }

  a {
    width: 100%;
  }

  @media (max-width: 500px) {
    width: 90vw;
  }
  
  &.add-to-playlist {
    @media (max-width: 600px) {
      width: 100%;
      padding-left: 0!important;
      padding-right: 0!important;
    }
  }
`;
export const ContainerForm = styled.form`
  width: 480px;
  border-radius: 21px;
  background-color: #fff;
  font-size: 18px;
  font-weight: bold;
  padding: 30px;
  box-sizing: border-box;
  transition: all 250ms ease-in-out;

  &.invite-folder-modal {
    position: relative;
    width: 600px;
    min-height: 190px;
    @media (max-width: 600px) {
      width: 100%;
      min-height: 260px;
    }
  }

  a {
    width: 100%;
  }

  @media (max-width: 500px) {
    width: 90vw;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 25px;
  text-align: center;

  .ellipsis {
    text-overflow: ellipsis;
    max-width: 250px;
    white-space: nowrap;
    display: inline-block;
    overflow: hidden;
  }

  @media (max-width: 480px) {
    font-size: 16px;
  }
`;

export const Content = styled.div`
  padding: 30px;
  width: 480px;
  box-sizing: border-box;
  text-align: center;
  min-height: 220px;
  
  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;

export const Message = styled.div`
  align-items: center;
  background-color: #fff;
  font-weight: normal;
  font-size: 15px;
`;

export const Label = styled.div`
  font-size: 13px;
  font-weight: 500;
  text-align: left;
  margin-bottom: 10px;
`;

export const LabelBold = styled(Label)`
  font-weight: bold;
`;

export const CheckboxLabelText = styled.div`
  display: inline-block;
  font-size: 13px;
  font-weight: 500;
  text-align: left;
  margin-bottom: 10px;
  margin-bottom: 0;
  margin-left: 23px;
  cursor: pointer;
`;

export const CheckboxLabel = styled.label`
  padding: 0;
  margin: 0;
`;

export const Footer = styled.div`
  display: flex;
  margin-top: 30px;
  padding: 0;
  width: 100%;

  &.single {
    display: block;
  }

  a {
    display: flex;
    width: 100%;
    max-width: 100%;
    font-size: 15px;
    font-weight: normal;
    justify-content: center;
  }

  @media (max-width: 480px) {
    flex-direction: column;

    a {
      margin-left: 0 !important;
      margin-right: 0;
    }

    a:first-child {
      margin-bottom: 10px;
    }
  }
`;

export const Title = styled.div`
  display: inline-block;
  font-size: 15px;
  font-weight: normal;
  margin: 15px 0 0 0;
`;

export const ActionIcon = styled.div`
  display: inline-block;
  width: 26px;
  height: 26px;
  cursor: pointer;

  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`;

const CloseButton = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  width: 12px;
  height: 12px;
  margin: 12px;
  cursor: pointer;

  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(${require("../../../root/img/dlink/close.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/dlink/close@2x.png")});
  }

  &:hover {
    opacity: 0.8;
  }
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  margin-top: 15px;
`;

export const Box = styled.div`
  display: flex;
  border-radius: 6px;
  border: 1px solid #eee;
  /* height: 85px; */
  padding: 11px 11px;
  justify-content: space-between;
  background-color: #fafafa;
  font-size: 15px;
  word-break: break-all;
  &.responsive {
    overflow-x: auto;
  }
`;

export const WrapperElements = styled.div`
  display: flex;
  text-align: left;
  justify-content: center;
  flex-direction: column;

  &.wrap {
    width: 100%;
  }
`;

export const Element = styled.div`
  display: block;
  font-size: 15px;
  font-weight: normal;
  box-sizing: border-box;
  padding: 5px;
`;

export const ElementBold = styled(Element)`
  font-weight: bold;
  &.nowrap {
    white-space: nowrap;
  }
`;

export const ElementUnderLine = styled(Element)`
  text-decoration: underline;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
  min-height: 28px;
`;

export const FolderIcon = styled.div`
  display: inline-block;
  width: 24px;
  height: 24px;
  cursor: pointer;

  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
`;

export const ErrorMessage = styled.div`
  color: #ff4d4d;
  line-height: 15px;
  margin-bottom: 10px;
  min-width: initial;
  min-height: 15px;
  font-size: 13px;
  text-align: left;
`;

export const Pulsate = keyframes`
  0% {transform: scale(0.1, 0.1); opacity: 0;}
  50% {opacity: 1;}
  100% {transform: scale(1.2, 1.2); opacity: 0;}
`;

export const Loader = styled.div`
  position: absolute;
  width: 50px;
  height: 50px;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
  border: 4px solid #20bed6;
  border-radius: 30px;
  animation: ${Pulsate} 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 0;
`;

export const ExistErrorMessage = styled.div`
  font-size: 15px;
  font-weight: normal;
  text-overflow: ellipsis;
  text-align: left;
  overflow: hidden;
`;

export const OverwriteMessage = styled.div`
  display: flex;
  font-size: 15px;
  font-weight: normal;
  flex-wrap: wrap;
  justify-content: center;

  span {
    display: inline-block;
    height: 20px;
  }

  .ellipsis {
    font-weight: 500;
    text-overflow: ellipsis;
    max-width: 250px;
    white-space: nowrap;
    display: inline-block;
    overflow: hidden;
  }
`;

export const LoaderWrapper = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  min-height: 450px;
  width: 420px;
  z-index: 1000;
  background-color: #fff;

  @media (max-width: 480px) {
    width: calc(90vw - 60px);
  }
`;

export const NameEllipsis = styled.div`
  font-weight: normal;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  overflow: hidden;
`;

export const Table = styled.div`
  display: flex;
  font-size: 15px;
  border-radius: 6px;
  border: 1px solid #eee;
  word-break: break-all;
  flex-direction: column;
  font-weight: normal;
  word-break: break-all;

  .iconwrap {
    min-width: auto;
  }
`;

export const TableHeader = styled.div`
  display: flex;
  align-items: center;
  background-color: #fafafa;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  border-bottom: 1px solid #eee;
  padding: 10px;
  box-sizing: border-box;
`;

export const TableBody = styled.div`
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  padding: 10px 10px;
`;

export const TableRow = styled.div`
  display: flex;
  justify-content: space-around;
  height: 30px;
`;

export const TableCol = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 100%;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  align-items: center;
  line-height: 30px;

  .iconwrap {
    min-width: auto;
    display: flex;
    margin: 0 5px 0 0;
    clear: both;
    justify-content: center;
  }

  .text {
    padding: 0;
  }

  &:first-child {
    font-weight: bold;
  }
`;

export const LinkWrapper = styled.div`
  line-height: 40px;
  height: 40px;
  padding: 0 10px;
  border: 1px solid #ccc;
  font-size: 14px;
  border-radius: 5px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  &[disabled] {
    border: solid 1px #c1c1c1;
    background-color: #fafafa;
  }
`;

export const LinkInput = styled.input`
  display: flex;
  flex: 1;
  outline: none;
  border: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-family: "Roboto", sans-serif;
  ${props => {
    if (props.disabled) {
      return `
        background: none;
        color: #000000 !important;
      `;
    }
  }}
  transition: all 200ms ease-in-out;
`;

export const CopyLink = styled.div`
  margin-left: 10px;
  cursor: ${props => (props.disabled ? "default" : "pointer")};
  font-size: 13px;
  font-weight: 500;
  color: ${props => (props.disabled ? "#c1c1c1" : "#17bed0")};
  transition: all 200ms ease-in-out;
`;

export const Row = styled.div`
  display: flex;
  padding-bottom: 5px;
  align-items: center;

  div.iconwrap {
    margin: 0 10px 0 0;
    width: 24px;
    height: 25px;
    min-width: 24px;
  }
`;

export const InputGridTable = styled.div`
  display: grid;
  gap: 18px 12px;

  &.template-2x4  {
    grid-template-rows: auto auto auto auto;
    grid-template-columns: 1fr 1fr;

    .r1c1 {
      grid-column: 1/2;
      grid-row: 1;
    }

    .r1c2 {
      grid-column: 2/3;
      grid-row: 1;
    }

    .r2c1 {
      grid-column: 1/2;
      grid-row: 2;
    }

    .r2c2 {
      grid-column: 2/3;
      grid-row: 2;
    }

    .r3c1 {
      grid-column: 1/3;
      grid-row: 3;
    }

    .r4c1 {
      grid-column: 1/2;
      grid-row: 4;
    }

    .r4c2 {
      grid-column: 2/3;
      grid-row: 4;
    }
  }

  &.template-1x2  {
    grid-template-rows: auto auto;
    grid-template-columns: 1fr;

    .r1c1 {
      grid-column: 1/2;
      grid-row: 1;
    }

    .r2c1 {
      grid-column: 1/2;
      grid-row: 2;
    }
  }
`;

const shake = keyframes`
    10%, 90% {
        transform: translate3d(-1px, 0, 0);
    }
    
    20%, 80% {
        transform: translate3d(2px, 0, 0);
    }

    30%, 50%, 70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%, 60% {
        transform: translate3d(4px, 0, 0);
    }
`;

export const InputWrapper = styled.div`
  .intl-tel-input {
    input {
      width: 100%;
      padding: 10px;
      font-size: 15px;
      text-align: left;
      color: #000;
      border-radius: 5px;
      border: solid 1px #a1a1a1;
      background-color: #fff;
      outline: none;
      box-sizing: border-box;
    }

    ul {
      white-space: normal;
      border-radius: 5px;
      border-radius: 9px;
      border: none;
      top: calc(100% + 10px);

      li {
        font-size: 14px;
        font-weight: normal;

        .dial-code {
          font-weight: bold;
          color: #000;
        }
      }
    }
  }

  ${({ invalid }) => {
    if (invalid) {
      return `
        animation-name: ${shake};
        animation-duration: 700ms;
        animation-delay: 500ms;
        animation-timing-function: ease-in-out;
        animation-direction: alternate;
        animation-fill-mode: forwards;

        input, textarea {
          border-color: #C2313A !important;
        }

        label {
          color: #C2313A !important;
        }
      `;
    }
  }}
`

export const Input = styled.input`
  width: 100%;
  padding: 10px;
  font-size: 14px;
  text-align: left;
  color: #000;
  border-radius: 5px;
  border: solid 1px #a1a1a1;
  background-color: #fff;
  outline: none;
  box-sizing: border-box;
`;

export const TextArea = styled.textarea`
  width: 100%;
  padding: 10px;
  font-size: 14px;
  text-align: left;
  color: #000;
  border-radius: 5px;
  border: solid 1px #a1a1a1;
  background-color: #fff;
  outline: none;
  box-sizing: border-box;
`;

export const Requirements = styled.div`
  text-align: left;
  font-style: italic;
  font-size: 12px;
  color: #999;
  margin-top: 3px;
  font-weight: normal;
`;
