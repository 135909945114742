// @flow
import { parseLinkData } from "../../../containers/ShareLinkSettings/utils";
import {
  LOAD_SHARED_LINKS,
  ADD_SHARED_LINK,
  DELETE_SHARED_LINK,
  LOAD_LINK_DATA,
  SET_UPLOAD_SETTINGS,
  SET_DOWNLOAD_SETTING,
  SET_HAS_PASSWORD,
  SET_EXPIRES,
  SET_EMAILS_WITH_UPLOAD_ACCESS,
  SET_SHORT_LINK_URL
} from "../constants/sharedLinks";

export type SharedLinksAction =
  | LoadSharedLinks
  | AddSharedLink
  | DeleteSharedLink
  | LoadSharedLinkData
  | SetUploadSettings
  | SetDownloadSettings
  | SetHasPassword
  | SetExpires
  | SetEmailsWithUploadAccess
  | SetShortLinkUrl;

type LoadSharedLinks = { type: typeof LOAD_SHARED_LINKS, payload: Array<any> };
type AddSharedLink = { type: typeof ADD_SHARED_LINK, payload: data };
type DeleteSharedLink = { type: typeof DELETE_SHARED_LINK, payload: data };
type LoadSharedLinkData = { type: typeof LOAD_LINK_DATA, linkData: any };
type SetUploadSettings = { type: typeof SET_UPLOAD_SETTINGS, settings: any };
type SetDownloadSettings = { type: typeof SET_DOWNLOAD_SETTING, canDownload: boolean };
type SetHasPassword = { type: typeof SET_HAS_PASSWORD, payload: boolean };
type SetExpires = { type: typeof SET_EXPIRES, payload: boolean };
type SetEmailsWithUploadAccess = {
  type: typeof SET_EMAILS_WITH_UPLOAD_ACCESS,
  emails: Array<string>
};
type SetShortLinkUrl = {
  type: typeof SET_SHORT_LINK_URL,
  payload: boolean
};

export const loadSharedLinks = (links: ListShares): LoadSharedLinkss => {
  return { type: LOAD_SHARED_LINKS, payload: links };
};

export const addSharedLink = (data: any): AddSharedLink => {
  return { type: ADD_SHARED_LINK, payload: data };
};

export const deleteSharedLink = (data: any): DeleteSharedLink => {
  return { type: DELETE_SHARED_LINK, payload: data };
};

export function loadSharedLinkData(linkData: any): LoadSharedLinkData {
  return { type: LOAD_LINK_DATA, linkData: parseLinkData(linkData) };
}

export function setUploadSettings(settings: any): SetUploadSettings {
  return { type: SET_UPLOAD_SETTINGS, settings };
}

export function setDownloadSettings(canDownload: Boolean): SetDownloadSettings {
  return { type: SET_DOWNLOAD_SETTING, canDownload };
}

export function setHasPassword(payload: boolean): SetHasPassword {
  return { type: SET_HAS_PASSWORD, payload };
}

export function setExpires(payload: boolean): SetExpires {
  return { type: SET_EXPIRES, payload };
}

export function setEmailsWithUploadAccess(emails: Array<string>): SetEmailsWithUploadAccess {
  return { type: SET_EMAILS_WITH_UPLOAD_ACCESS, emails };
}

export function setShortLinkUrl(payload: boolean): SetShortLinkUrl {
  return { type: SET_SHORT_LINK_URL, payload };
}

export const sharedLinkActionCreators = {
  loadSharedLinks,
  addSharedLink,
  deleteSharedLink,
  loadSharedLinkData,
  setUploadSettings,
  setDownloadSettings,
  setHasPassword,
  setExpires,
  setEmailsWithUploadAccess,
  setShortLinkUrl
};
