// @flow

import * as React from "react";
import { hiDPI } from "polished";
import { findDOMNode, createPortal } from "react-dom";
import styled from "styled-components";
import { CSSTransition } from "react-transition-group";

import CloseIcon from "../images/svg/close-modal.svg";

type ModalProps = {
  opened: boolean,
  offsetX?: string,
  offsetY?: string,
  top?: number,
  left?: number,
  fullSize?: boolean,
  holderDisplay?: string,
  isFixed?: boolean,
  shouldCloseOnOverlayClick: boolean,
  shouldCloseOnEscape: boolean,
  children: any,
  onClose: () => void,
  style?: any,
  container?: any,
  height?: any,
  width?: any,
  margin?: any,
};

const escapeKey = 27;

class Modal extends React.Component<ModalProps> {
  modalRef: ?HTMLElement;
  modalContainer: ?HTMLElement;

  static defaultProps = {
    opened: false,
    top: 0,
    left: 0,
    shouldCloseOnOverlayClick: true,
    shouldCloseOnEscape: true,
    onClose: () => {},
    style: {}
  };

  constructor(props: ModalProps) {
    super(props);

    (this: any)._onOutsideClick = this._onOutsideClick.bind(this);
    const modalContainer = null;
  }

  componentDidMount() {
    const { opened, onClose, shouldCloseOnEscape } = this.props;

    document.onkeyup = (e) => {
      if (shouldCloseOnEscape && escapeKey === e.keyCode) {
        onClose();
      }
    }
    
    if (opened) { 
      // Opened on initial load -> remove page scroll
      $("html").addClass("g-modal-open");
    } else {
      $("html").removeClass("g-modal-open");
    }
  }

  componentDidUpdate({ opened: prevOpened }: ModalProps) {
    const { opened, shouldCloseOnEscape, onClose } = this.props;
    
    if (opened !== prevOpened) {
      if (opened) { // remove page scroll
        $("html").addClass("g-modal-open");
      } else {
        $("html").removeClass("g-modal-open");
      }
    }

    document.onkeyup = (e) => {
      if (shouldCloseOnEscape && escapeKey === e.keyCode) {
        onClose();
      }
    }
  }

  componentWillUnmount() {
    if (this.props.opened) {
      // rollback page scroll
      $("html").removeClass("g-modal-open");
    }
  }

  _onOutsideClick({ target, currentTarget }: MouseEvent) {
    console.log("🚀 ~ file: Modal.js:93 ~ Modal ~ _onOutsideClick ~ { target, currentTarget }:", { target, currentTarget })
    const { opened, shouldCloseOnOverlayClick, onClose } = this.props;

    if (!shouldCloseOnOverlayClick) {
      return;
    }

    if (!opened || !(currentTarget instanceof HTMLElement) || !(target instanceof HTMLElement)) {
      return;
    }

    if (currentTarget.isEqualNode(target)) {
      onClose();
    }
  }

  render() {
    const {
      opened,
      children,
      onClose,
      offsetX,
      offsetY,
      fullSize,
      style,
      top,
      left,
      holderDisplay,
      isFixed,
      container,
      height,
      width,
      margin
    } = this.props;

    const mouseOffset =
      offsetX && offsetY ? `${offsetX}px ${offsetY}px` : `center`;

    return (
      createPortal(
        <FadeTranstionContainer>
          <CSSTransition
            in={opened}
            unmountOnExit
            timeout={{
              enter: 0,
              exit: 300
            }}
            classNames="fade"
            onEnter={() => {}}
            onExited={() => {}}
            appear
          >
            <Overlay
              onMouseDown={this._onOutsideClick}
              ref={ref => (this.modalContainer = ref)}
              style={style || {}}
              fullSize={fullSize}
            >
              <CSSTransition
                in={opened}
                timeout={{
                  enter: 0,
                  exit: 300
                }}
                classNames="fade-scale"
                onEnter={() => {}}
                onExited={() => {}}
                appear
              >
                <ModalHolder
                  top={top}
                  left={left}
                  ref={ref => (this.modalRef = ref)}
                  mouseOffset={mouseOffset}
                  fullSize={fullSize}
                  holderDisplay={holderDisplay}
                  height={height}
                  width={width}
                  margin={margin}
                >
                  {children}
                </ModalHolder>
              </CSSTransition>
            </Overlay>
          </CSSTransition>
        </FadeTranstionContainer>,
        container || document.body
      )
    );
  }
}

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.35);
  text-align: center;
  z-index: 20000;
  overflow-y: auto;
  opacity: 0;
  transition: all 300ms ease-in-out;
  display: flex;
  justify-content: center;
  align-items:${props => (props.fullSize ? "center" : "flex-start")};
`;

const ModalHolder = styled.div`
  position: ${props => (props.top ? `absolute` : `initial`)};
  display: ${props => (props.holderDisplay ? props.holderDisplay : `inline-block`)};
  margin: ${props => (props.margin ? props.margin : (props.fullSize ? `0px 10px` : `50px 10px`))};
  top: ${props => (props.top ? props.top + "px" : `0`)};
  left: ${props => (props.left ? props.left + "px" : `0`)};
  transform-origin: ${props => props.mouseOffset};
  opacity: 0;
  transition: all 300ms ease-in-out;
  width: ${props => props.width || "auto"};
  height: ${props => props.height || "auto"};
`;

export const ModalWrap = styled.div`
  display: inline-block;
  margin: 30px auto;
  box-shadow: 0 15px 35px 0 rgba(0, 0, 0, 0.1);
  background: #fff;
  position: relative;
`;

export const OvalModalWrap = styled.div`
  display: inline-block;
  box-shadow: 0 15px 35px 0 rgba(0, 0, 0, 0.1);
  background: #fff;
  position: relative;
  border-radius: 18px;
  overflow: hidden;
  overflow: ${props => (props.overflow ? props.overflow : 'hidden')};

  &.responsive-modal {
    @media (max-width: 800px) {
      width: 95vw;
    }
  }
`;

export const OvalModalClose = styled.div`
  position: ${props => (props.isFixed ? `fixed` : `absolute`)};
  right: 10px;
  top: 10px;
  background: rgba(0, 0, 0, 0.1) url(${require("../images/wclose.png")})
    no-repeat 50%;
  border-radius: 30px;
  width: 32px;
  height: 32px;
  box-sizing: border-box;
  cursor: pointer;
  opacity: 0.35;
  z-index: 41;
  &:hover {
    opacity: 1;
  }
`;

export const OvalModalCloseSimple = styled(OvalModalClose)`
  right: 20px;
  top: 26px;
  width: 14px;
  height: 14px;
  margin-top: 5px;
  background-repeat: no-repeat;
  background-position: center;
  background: none;
  background-size: 14px 14px;
  background-image: url(${require("../images/close-modal.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../images/close-modal@2x.png")});
  }
`;

export const OvalModalCloseV2 = styled(OvalModalCloseSimple)`
  right: 15px;
  top: 15px;
  margin-top: 0;
`;

export const CloseModalIcon = styled(CloseIcon)`
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
  z-index: 1;
`;

export const OvalModalBody = styled.div`
  padding: 20px;
`;

const ModalHeader = styled.div`
  font-size: 12px;
  color: #7b7b7b;
  background: #eee;
  border-bottom: 1px solid #e9e9e9;
  height: 33px;
  line-height: 33px;
  font-weight: 700;
  padding-left: 10px;
`;

const ModalClose = styled.div`
  float: right;
  cursor: pointer;
  width: 40px;
  height: 100%;
  background-size: 11px 11px;
  background-repeat: no-repeat;
  background-position: 50%;
  background-image: url(${require("../images/close-modal.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../images/close-modal@2x.png")});
  }
  opacity: 0.35;

  &:hover {
    opacity: 1;
  }
`;

const ModalTitle = styled.div`
  float: left;
  max-width: 300px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const ModalTop = ({
  onClose,
  children
}: {
  onClose: () => void
}) => (
  <ModalHeader>
    <ModalTitle>{children}</ModalTitle>
    <ModalClose onClick={onClose} />
  </ModalHeader>
);

export const ModalBody = styled.div`
  background: #fff;
  padding: 20px;
  text-align: left;
`;

export const ModalButtonArea = styled.div`
  background: #f4f4f4;
  padding: 15px;
  text-align: center;

  & > button {
    margin: 0 5px 0 5px;
  }
`;

export const SharpModalClose = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  background: url(${require("../images/close-white@2x.png")});
  background-repeat: no-repeat;
  background-size: 12px 12px;
  width: 12px;
  height: 12px;
  box-sizing: border-box;
  cursor: pointer;
  z-index: 41;
`;

const FadeTranstionContainer = styled.div`
  // Fade animation
  .fade-enter,
  .fade-appear {
    opacity: 0;
  }
  .fade-exit {
    opacity: 1;
  }
  .fade-enter-active
  .fade-appear-active {
    opacity: 1;
  }
  .fade-exit-active {
    opacity: 0;
  }

  .fade-appear-done,
  .fade-enter-done {
    opacity: 1;
  }

  // Modal holder scale-animation
  .fade-scale-enter,
  .fade-scale-appear {
    opacity: 0;
    transform: scale(0.5);
    /* transform: translateY(60px); */
    /* transform: scale(0.5) translateY(60px); */
  }
  .fade-scale-exit {
    opacity: 1;
    transform: scale(1);
    /* transform: translateY(0px); */
    /* transform: scale(1) translateY(0px); */
  }
  .fade-scale-enter-active
  .fade-scale-appear-active {
    opacity: 1;
    transform: scale(1);
    /* transform: translateY(0px); */
    /* transform: scale(1) translateY(0px); */
  }
  .fade-scale-exit-active {
    opacity: 0;
    transform: scale(0.5);
    /* transform: translateY(60px); */
    /* transform: scale(0.5) translateY(60px); */
  }

  .fade-scale-appear-done,
  .fade-scale-enter-done {
    opacity: 1;
    transform: scale(1);
    /* transform: translateY(0px); */
    /* transform: scale(1) translateY(0px); */
  }
`; 

export default Modal;
