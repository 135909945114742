import React, { useId } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";

const Tooltip = ({ title, children, shouldRenderTooltip = true }) => {
    const id = useId();
    return (
        <>
            {shouldRenderTooltip && (
                <ReactTooltip id={id} className="cv-tooltip visible-on-mobile" noArrow={true}>
                    {title}
                </ReactTooltip>
            )}
            <span data-tooltip-id={id}>{children}</span>
        </>
    );
};

export default Tooltip;
