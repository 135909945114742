// @flow
// WARNING: Do not use HFN in this component

import React, { PureComponent } from "react";
import styled, { keyframes } from "styled-components";
import { hiDPI } from "polished";

import apiMethod from "../../api/apiMethod"; // DONE
import { __ } from "../../lib/translate"; // DONE
import { setcookie, getGlobalLocationId } from "../../lib/utils"; // DONE
import { DEVICE_OS, LOGIN_THEMES } from "@pcloud/web-utilities/dist/config/constants"; // DONE
import { TwoFactAuthLoginCodeForm } from "./TwoFactAuthLoginCodeForm"; // DONE
import Modal from "../../components/Modal"; // DONE
import { URLSITE } from "@pcloud/web-utilities/dist/config"; // DONE

import Heading from "./MultiplatformComponents/Elements/Heading"; // DONE
import SubHeading from "./MultiplatformComponents/Elements/SubHeading"; // DONE
import Wrapper from "./MultiplatformComponents/Elements/Wrapper"; // DONE
import { FormWrapper } from "./MultiplatformComponents/WindowsStyles"; // DONE
import { notify } from "./MultiplatformComponents/Notification"; // DONE

import BackIconTFA from "../../../root/img/2fa-login/back.svg"; // DONE

const notificationCodeForm = 1;
const smsCodeForm = 2;
const recoveryCodeForm = 3;
const authCodeForm = 4;

type LoggedDevicesModalProps = {
  devices: Array<Object>,
  isLoginModal: boolean,
  toggleLoggedDevices: () => void
};

function LoggedDevicesModal({
  os = 4,
  devices,
  isLoginModal,
  toggleLoggedDevices
}: LoggedDevicesModalProps) {
  const deviceRows =
    devices && devices.length > 0
      ? devices.map(function(device, index) {
          const key = device.name + index;
          return (
            <DeviceRow key={key}>
              {deviceIcons[device.type]}
              <DeviceTitle>{device.name}</DeviceTitle>
            </DeviceRow>
          );
        })
      : __("tfa_login_error_2301", "You don't have any devices with logged pCloud account.");

  return isLoginModal ? (
    <div>
      <Wrapper os={os} style={{ padding: "0px", margin: "0px" }}>
        <Header>
          <Heading os={os}>{__("tfa_login_logged_devices_header", "Logged devices")}</Heading>
          <SubHeading os={os}>{__("tfa_login_logged_devices_subheader", "You are currently logged in pCloud on the following devices or web browsers:")}</SubHeading>
        </Header>
        <DeviceContainer className="device-container">{deviceRows}</DeviceContainer>
      </Wrapper>
      <ModalBackButton className="back-button" onClick={toggleLoggedDevices}>{__("back")}</ModalBackButton>
    </div>
  ) : (
    <Modal opened onClose={toggleLoggedDevices}>
      <TwoFactAuthWrapper os={os} style={{ padding: "20px" }}>
        <Header>
          <Heading os={os}> {__("tfa_login_logged_devices_header", "Logged devices")} </Heading>
          <SubHeading os={os}>{__("tfa_login_logged_devices_subheader", "You are currently logged in pCloud on the following devices or web browsers:")}</SubHeading>
        </Header>
        <DeviceContainer className="device-container">{deviceRows}</DeviceContainer>
        <CloseButton className="back-button" onClick={toggleLoggedDevices} />
      </TwoFactAuthWrapper>
    </Modal>
  );
}

type CantReceiveCodeSupportProps = {
  theme: Object,
  canResend: boolean,
  isLoginModal: boolean,
  waitingTimeForSend: number,
  changeForm: () => void,
  toggleHelpSteps: () => void,
  toggleCanResend: () => void
};

function CantReceiveCodeSupport({
  os = 4,
  theme,
  isLoginModal,
  canResend,
  waitingTimeForSend,
  changeForm,
  toggleHelpSteps,
  toggleCanResend
}: CantReceiveCodeSupportProps) {
  const themeStyle = theme && LOGIN_THEMES[theme] ? LOGIN_THEMES[theme] : {};

  const content =
    window.innerWidth <= 460 ? (
      <ModalWrapper style={isLoginModal ? { margin: "0px", padding: "0px" } : {}}>
        <React.Fragment>
          {isLoginModal ? (
            <BackButton className="back-button" onClick={toggleHelpSteps} style={{ margin: "21px", ...themeStyle.tfaLink }}>
              <BackIconTFA style={{ width: "9px", fill: "#17bed0", ...themeStyle.tfaBackArrowSvg }}/>
              <span style={{marginLeft: "6px"}}>{__("back")}</span>
            </BackButton>
          ) : null}
          <Header>
            <Heading os={os}>{__("tfa_login_cant_receive_header", "Can't receive code?")}</Heading>
            <SubHeading os={os}>{__("tfa_login_cant_receive_subheader", "Try the following steps:")}</SubHeading>
          </Header>
          <StepsContainer className="steps-container" canResend={canResend}>
            <ol>
              <li>
                <span
                  className="send_link"
                  onClick={() => {
                    if (canResend) {
                      changeForm(smsCodeForm);
                      toggleHelpSteps();
                      toggleCanResend();
                    }
                  }}
                >
                  {__("tfa_login_cant_receive_mobile_step1", "Resend SMS")}
                </span>
                {!canResend ? <Counter>({waitingTimeForSend})</Counter> : null}
              </li>
              <li>
                <span
                  onClick={() => {
                    changeForm(notificationCodeForm);
                    toggleHelpSteps();
                  }}
                >
                  {__("tfa_login_cant_receive_mobile_step2", "Resend notification")}
                </span>
              </li>
              <li>
                <span
                  onClick={() => {
                    changeForm(recoveryCodeForm);
                    toggleHelpSteps();
                  }}
                >
                  {__("tfa_login_cant_receive_mobile_step3", "Use recovery code")}
                </span>
              </li>
              <li>
                <span onClick={() => window.open(URLSITE.indexOf('extension') ? "https://pcloud.com/request-help.html" : URLSITE + "request-help.html")}>
                  {__("tfa_login_cant_receive_mobile_step4", "Contact support")}
                </span>
              </li>
            </ol>
          </StepsContainer>
          {!isLoginModal ? <CloseButton onClick={toggleHelpSteps} /> : null}
        </React.Fragment>
      </ModalWrapper>
    ) : (
      <React.Fragment>
        <BackButton className="back-button" style={themeStyle.tfaLink} onClick={toggleHelpSteps}>
          <BackIconTFA style={{ width: "9px", fill: "#17bed0", ...themeStyle.tfaBackArrowSvg }} />
          <span style={{marginLeft: "6px"}}>{__("back")}</span>
        </BackButton>
        <Header>
          <Heading os={os} style={themeStyle.text}>{__("tfa_login_cant_receive_header", "Can't receive code?")}</Heading>
          <SubHeading os={os} style={themeStyle.text}>{__("tfa_login_cant_receive_subheader", "Try the following steps:")}</SubHeading>
        </Header>
        <StepsContainer className="steps-container" style={themeStyle.tfaSteps} canResend={canResend}>
          <ol>
            <li style={themeStyle.text}>
              {__(
                "tfa_login_cant_receive_step1.1",
                "If you haven't received an SMS with the security code yet, wait a few minutes. Some operators may take up to 5 min to proceed your SMS. If you don't receive an SMS even after sufficient amount of time, try"
                ) + " "}
              <span
                className="send_link"
                style={themeStyle.tfaLink}
                onClick={() => {
                  if (canResend) {
                    changeForm(smsCodeForm);
                    toggleHelpSteps();
                    toggleCanResend();
                  }
                }}
              >
                {__("tfa_login_cant_receive_step1.2", "resending the SMS")}
              </span>
              .{!canResend ? <Counter>({waitingTimeForSend})</Counter> : null}
            </li>
            <li style={themeStyle.text}>
              {__("tfa_login_cant_receive_step2.1", "If you have access to devices, where your pCloud account is logged, try") + " "}
              <span
                style={themeStyle.tfaLink}
                onClick={() => {
                  changeForm(notificationCodeForm);
                  toggleHelpSteps();
                }}
              >
                {__("tfa_login_cant_receive_step2.2", "receiving the code as a notification.")}
              </span>
            </li>
            <li style={themeStyle.text}>
              {__("tfa_login_cant_receive_step3.1", "If you don't receive an SMS or don't have access to logged devices,") + " "}
              <span
                style={themeStyle.tfaLink}
                onClick={() => {
                  changeForm(recoveryCodeForm);
                  toggleHelpSteps();
                }}
              >
                {__("tfa_login_cant_receive_step3.2", "use one of your recovery codes")}
              </span>{" "}
              {__("tfa_login_cant_receive_step3.3", "to access pCloud.")}
            </li>
            <li style={themeStyle.text}>
              {__("tfa_login_cant_receive_step4.1", "If you don't have your recovery codes at hand,") + " "}
              <span
                style={themeStyle.tfaLink}
                onClick={() => window.open(URLSITE.indexOf('extension') ? "https://pcloud.com/request-help.html" : URLSITE + "request-help.html")}
              >
                {__("tfa_login_cant_receive_step4.2", "contact our support team")}
              </span>{" "}
              {__("tfa_login_cant_receive_step4.3", "to find a solution. You may be asked to provide some additional info to prove your identity.")}
            </li>
          </ol>
        </StepsContainer>
      </React.Fragment>
    );

  if (window.innerWidth <= 460 && !isLoginModal) {
    return (
      <Modal opened onClose={toggleHelpSteps}>
        {content}
      </Modal>
    );
  } else {
    return content;
  }
}

type Error = {
  errorMessage: string,
  wrongCode: boolean,
  showError: boolean
};

type TwoFactorAuthLoginProps = {
  token: string,
  theme: Object,
  tfatype: number,
  hasLoggedDevices: boolean,
  rememberme: boolean,
  isLoginModal: boolean,
  logininfo?: string,
  onSuccess: () => void
};

type TwoFactorAuthLoginState = {
  formID: number,
  token: string,
  sessionTimedOut: boolean,
  code: string,
  isSafeDevice: boolean,
  error: Error,
  showLoading: boolean,
  showLoggedDevices: boolean,
  showHelpSteps: boolean,
  requestFinished: boolean,
  canResend: boolean,
  waitingTimeForSend: number
};

export default class TwoFactorAuthLogin extends PureComponent<
  TwoFactorAuthLoginProps,
  TwoFactorAuthLoginState
> {
  static defaultProps = {
    os: 4,
    logininfo: ""
  };

  constructor(props: TwoFactorAuthLoginProps) {
    super(props);

    let formID;
    const { tfatype, hasLoggedDevices, token } = this.props;

    if (tfatype == 1) {
      if (hasLoggedDevices) {
        formID = notificationCodeForm;
      } else {
        formID = smsCodeForm;
      }
    } else if (tfatype == 2) {
      formID = authCodeForm;
    }

    this.state = {
      formID,
      token: token,
      sessionTimedOut: false,
      code: "",
      isSafeDevice: false,
      error: {
        errorMessage: "Error!",
        wrongCode: false,
        showError: false
      },
      showLoading: false,
      showLoggedDevices: false,
      showHelpSteps: false,
      requestFinished: true,
      canResend: true,
      waitingTimeForSend: 60
    };
    this.updateCode = this.updateCode.bind(this);
    this.updateIsSafeDevice = this.updateIsSafeDevice.bind(this);
    this.toggleLoggedDevices = this.toggleLoggedDevices.bind(this);
    this.changeForm = this.changeForm.bind(this);
    this.toggleHelpSteps = this.toggleHelpSteps.bind(this);
    this.showError = this.showError.bind(this);
    this.tfaLogin = this.tfaLogin.bind(this);
    this.sendSMS = this.sendSMS.bind(this);
    this.sendNotification = this.sendNotification.bind(this);
    this.toggleCanResend = this.toggleCanResend.bind(this);
    this.ressetError = this.ressetError.bind(this);
  }

  showError(errorData) {
    const { os } = this.props;

    if (errorData.result == 2064) {
      this.setState({ sessionTimedOut: true });
    }
    const tfaErrorList = {
      "1000": __("tfa_login_error_1000", "Log in required."),
      "1022": __("tfa_login_error_2012", "Incorrect security code."),
      "2000": __("tfa_login_error_2000", "Log in failed."),
      "2012": __("tfa_login_error_2012", "Incorrect security code."),
      "2064": __("tfa_login_error_2064", "You session timed out. Please, login again."),
      "2074": __("tfa_login_error_2074", "Expired security code"),
      "2092": __("tfa_login_error_2092", "This 'code' was already used"),
      "2255": __("tfa_login_error_2255", "You've reached your limit for receiving security codes. Try again later."),
      "2301": __("tfa_login_error_2301", "You don't have any devices with logged pCloud account."),
      "3012": __("tfa_login_error_3012", "We can't deliver an SMS to the provided phone number."),
      "4000": __("tfa_login_error_4000", "Too many login tries from this IP address."),
      "4007": __("tfa_login_error_4007", "Couldn't send SMS at this time. Please, try again later.")
    };
    
    if (os === DEVICE_OS.Web || os === DEVICE_OS.Android || os === DEVICE_OS.iOS) {
      this.setState({
        error: {
          errorMessage: tfaErrorList[errorData.result],
          wrongCode: errorData.result == 2012,
          showError: true
        }
      });
    } else if (os !== DEVICE_OS.Web) {
      notify(tfaErrorList[errorData.result], "error");
    }
  }

  ressetError() {
    this.setState({
      error: {
        errorMessage: "Error!",
        wrongCode: false,
        showError: false
      }
    });
  }

  sendNotification() {
    const { token } = this.state;
    apiMethod(
      "tfa_sendcodeviasysnotification",
      { token: token },
      res => {
        this.setState({ loggedDevices: res.devices, requestFinished: true });
      },
      {
        errorCallback: ret => {
          this.setState({ requestFinished: true });
          this.showError(ret);
        }
      }
    );
  }

  sendSMS() {
    const { token } = this.state;
    apiMethod(
      "tfa_sendcodeviasms",
      { token: token },
      res => {
        const phoneNumber = "+" + res.phonedata.countrycode + " " + res.phonedata.msisdn;
        this.setState({ phoneNumber: phoneNumber, requestFinished: true });
      },
      {
        errorCallback: ret => {
          this.setState({ requestFinished: true });
          this.showError(ret);
        }
      }
    );
  }

  tfaLogin() {
    const { rememberme, logininfo, onSuccess } = this.props;
    const { formID, token, code, isSafeDevice } = this.state;
    const loginMethod = formID === recoveryCodeForm ? "tfa_loginwithrecoverycode" : "tfa_login";

    this.setState({ showLoading: true });

    apiMethod(
      loginMethod,
      {
        token: token,
        code: code,
        trustdevice: isSafeDevice
      },
      userInfo => {
        const recaptchaBadge = document.querySelector(".grecaptcha-badge");

        console.log("USER DATA:", userInfo);
        this.setState({ showLoading: false });
        setcookie("logininfo", logininfo);
        onSuccess({
          action: "login",
          userinfo: userInfo,
          rememberme: rememberme,
          locationid: getGlobalLocationId()
        });

        if (recaptchaBadge) {
          recaptchaBadge.style.visibility = "hidden";
        }
      },
      {
        errorCallback: ret => {
          this.showError(ret);
          this.setState({ showLoading: false });
        }
      }
    );
  }

  updateCode(value) {
    console.log("🚀 ~ file: TwoFactorAuthLogin.js:441 ~ updateCode ~ value", value)
    this.setState({ code: value });
    this.ressetError();
  }

  updateIsSafeDevice() {
    this.setState(prevState => ({
      isSafeDevice: !prevState.isSafeDevice
    }));
  }

  toggleHelpSteps() {
    this.setState(prevState => ({
      showHelpSteps: !prevState.showHelpSteps
    }));
  }

  toggleLoggedDevices() {
    this.setState(prevState => ({
      showLoggedDevices: !prevState.showLoggedDevices
    }));
  }

  changeForm(formID) {
    const { canResend } = this.state;

    this.setState({
      formID: formID,
      code: "",
      phoneNumber: "",
      error: {
        errorMessage: "Error!",
        wrongCode: false,
        showError: false
      },
      requestFinished:
        formID == recoveryCodeForm ||
        (formID == smsCodeForm && !canResend) ||
        formID == authCodeForm
          ? true
          : false
    });
    if (formID == notificationCodeForm) {
      this.sendNotification();
    }
    if (formID == smsCodeForm && canResend) {
      this.sendSMS();
    }
  }

  toggleCanResend() {
    const { waitingTimeForSend, canResend, time } = this.state;

    if (waitingTimeForSend == 0) {
      this.setState(prevState => ({
        canResend: !prevState.canResend
      }));
    }

    if (canResend) {
      let remainingTime = waitingTimeForSend;
      const timer = setInterval(() => {
        remainingTime--;

        if (remainingTime === 0) {
          clearInterval(timer);
          this.setState({ canResend: true });
          remainingTime = 60;
        }
        this.setState({ waitingTimeForSend: remainingTime });
      }, 1000);
      
      this.setState({
        time: timer,
        canResend: false
      });
    }
  }

  render() {
    const { isLoginModal, theme, os } = this.props;
    const {
      showLoggedDevices,
      loggedDevices,
      showHelpSteps,
      canResend,
      waitingTimeForSend,
      formID,
      error,
      phoneNumber,
      isSafeDevice,
      code,
      showLoading,
      sessionTimedOut,
      requestFinished
    } = this.state;

    return (
      <Wrapper os={os} className="two-fact-wrapper">
        {showLoggedDevices ? (
          <LoggedDevicesModal
            os = {os}
            devices={loggedDevices}
            toggleLoggedDevices={this.toggleLoggedDevices}
            isLoginModal={isLoginModal}
          />
        ) : null}
        {showHelpSteps ? (
          <CantReceiveCodeSupport
            os = {os}
            changeForm={this.changeForm}
            toggleHelpSteps={this.toggleHelpSteps}
            isLoginModal={isLoginModal}
            canResend={canResend}
            toggleCanResend={this.toggleCanResend}
            waitingTimeForSend={waitingTimeForSend}
            theme={theme}
          />
        ) : null}
        <TwoFactAuthLoginCodeForm
          os = {os}
          formID={formID}
          error={error}
          userPhoneNumber={phoneNumber}
          updateCode={this.updateCode}
          updateIsSafeDevice={this.updateIsSafeDevice}
          isSafeDevice={isSafeDevice}
          code={code}
          showLoading={showLoading}
          toggleLoggedDevices={this.toggleLoggedDevices}
          changeForm={this.changeForm}
          toggleHelpSteps={this.toggleHelpSteps}
          sessionTimedOut={sessionTimedOut}
          tfaLogin={this.tfaLogin}
          sendSMS={this.sendSMS}
          sendNotification={this.sendNotification}
          hide={showHelpSteps || (showLoggedDevices && isLoginModal)}
          requestFinished={requestFinished}
          canResend={canResend}
          toggleCanResend={this.toggleCanResend}
          waitingTimeForSend={waitingTimeForSend}
          theme={theme}
        />
      </Wrapper>
    );
  }
}

const fadein = keyframes`
  0% {
    opacity: 0;
    transform:scale(0.92, 0.92);
  }

  100% {
    opacity: 1;
    transform:scale(1, 1);
  }
`;

const TwoFactAuthWrapper = styled.div`
  padding: 10px 0px;
  box-sizing: border-box;
  position: relative;
  background: #fff;
  max-width: 600px;
  min-width: 270px;
  border-radius: 8px;
  animation-name: ${fadein};
  animation-duration: 150ms;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
  animation-fill-mode: forwards;
  @media (max-width: 460px) {
    padding: 12px;
    margin: 15px;
  }
`;

const Header = styled.div`
  text-align: left;
  width: 100%;

  @media (max-width: 460px) {
    padding: 20px 10px;
    text-align: left;
  }
`;

const DeviceContainer = styled.div`
  background: #f8f8f8;
  border: 1px solid #eee;
  padding: 8px;
  min-height: 10px;
  margin: 25px 0px;
  margin-bottom: 0px;
  color: #666;
  font-size: 13px;
  text-align: left;

  @media (max-width: 460px) {
    margin: 20px 10px;
    margin-bottom: 0px;
    padding: 5px;
    background: none;
    border: none;
    color: black;
  }
`;

const DeviceRow = styled.div`
  margin: 12px 5px;
  text-align: left;
`;

const DeviceIcon = styled.div`
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-size: cover;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  background-position: center;
`;

const UnknownDeviceIcon = styled(DeviceIcon)`
  background-image: url(${require("../../../root/img/2fa-login/unknown.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/2fa-login/unknown@2x.png")});
  }
`;

const AndroidDeviceIcon = styled(DeviceIcon)`
  background-image: url(${require("../../../root/img/2fa-login/mobile-other.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/2fa-login/mobile-other@2x.png")});
  }
`;

const IosDeviceIcon = styled(DeviceIcon)`
  background-image: url(${require("../../../root/img/2fa-login/mobile-apple.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/2fa-login/mobile-apple@2x.png")});
  }
`;

const WebDeviceIcon = styled(DeviceIcon)`
  background-image: url(${require("../../../root/img/2fa-login/web.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/2fa-login/web@2x.png")});
  }
`;

const DesktopDeviceIcon = styled(DeviceIcon)`
  background-image: url(${require("../../../root/img/2fa-login/desktop-other.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/2fa-login/desktop-other@2x.png")});
  }
`;

const DeviceTitle = styled.span`
  vertical-align: middle;
`;

const CloseButton = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  background-repeat: no-repeat;
  background-size: cover;
  width: 25px;
  height: 25px;
  margin: 12px;
  background-position: center;
  cursor: pointer;

  background-image: url(${require("../../../root/img/updatedrive/close.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/updatedrive/close@2x.png")});
  }

  &:hover {
    opacity: 0.8;
  }
`;

const BackButton = styled.div`
  align-self: flex-start;
  margin-bottom: 10px;
  cursor: pointer;
  color: #17bed0;
  font-size: 12px;
`;

const ModalBackButton = styled(BackButton)`
  top: 25px;
  left: 30px;
`;

const StepsContainer = styled(DeviceContainer)`
  background: #fefef0;
  border: 1px solid #f2f2ed;
  padding: 16px;
  color: #222;
  max-width: 350px;
  box-sizing: border-box;

  & ol {
    /* padding: 0px 15px; */
  }
  & li {
    font-size: 14px;
    margin: 16px 0px;
  }
  & span {
    color: #17bed0;
    cursor: pointer;
  }
  & .send_link {
    color: ${props => (!props.canResend ? "#222" : "#17bed0")};
    cursor: ${props => (!props.canResend ? "not-allowed" : "pointer")};
  }
`;

const Counter = styled.span`
  display: inline-block;
  margin-left: 5px;
  color: red !important;
`;

const ModalWrapper = styled.div`
  padding: 10px 0px;
  box-sizing: border-box;
  position: relative;
  background: #fff;
  max-width: 600px;
  min-width: 270px;
  border-radius: 8px;
  animation-name: ${fadein};
  animation-duration: 150ms;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
  animation-fill-mode: forwards;
  @media (max-width: 460px) {
    padding: 12px;
    margin: 15px;
  }
`;


const deviceIcons = {
  0: <UnknownDeviceIcon />,
  1: <AndroidDeviceIcon />,
  2: <IosDeviceIcon />,
  3: <WebDeviceIcon />,
  4: <DesktopDeviceIcon />
};
