// @flow

import { URLMY } from "@pcloud/web-utilities/dist/config";
import type { Notification, PushNotification } from "./types";
import { getIconInfo, ICONS } from "../icons";
import { prepUrl } from "../../api/utils";
import { objMap } from "../../lib/utils";

export function notificationUrl(notification: Notification) {
  switch (notification.action) {
    case "gotofolder":
      return (window.location.origin ?? URLMY) + "#page=filemanager&folder=" + notification.folderid;
    case "openurl":
      return notification.url;
    case "opensharerequest":
      return (window.location.origin ?? URLMY) + "#page=shares&tab=requests-tab&accept=" + notification.sharerequestid;
    case "openfoldercomments":
      return (
          (window.location.origin ?? URLMY) +
        "#page=filemanager&parentfolderid=" +
        notification.parentfolderid +
        "&comments=d" +
        notification.folderid
      );
    case "openfilecomments":
      return (
          (window.location.origin ?? URLMY) +
        "#page=filemanager&parentfolderid=" +
        notification.parentfolderid +
        "&comments=f" +
        notification.fileid
      );
  }
}

export function enrichNotificationsWithUrl(
  notificationSources: Array<Notification>
): Array<Notification> {
  return notificationSources.map((notification: Notification) => {
    notification.url = notificationUrl(notification);

    return notification;
  });
}

export function transformNotificationForBrowser(push: PushNotification) {
  const { data: { notificationid, iconid, notification, action, thumb, coverthumb, systemnotificationid } } = push;

  const cover = coverthumb ? prepUrl(coverthumb) : undefined;
  const icon = thumb ? prepUrl(thumb) : getIconInfo(iconid, ICONS.NOTIFICATIONS).src;

  return {
    body: notification.replace(/<[^>]+>/g, ""),
    tag: notificationid,
    icon: icon,
    image: cover,
    data: push.data
  };
}

export function showBrowserNotification(push: PushNotification) {
  const showNotification = new self.Notification("pCloud Notification", transformNotificationForBrowser(push));

  console.log("the push", push);

  showNotification.onclick = () => {
    const url = notificationUrl(push.data);
    if (url) {
      self.location = url;
    }
  };
}

export function parsePayload(push: any) {
  const { data, ...rest } = push;

  return {
    ...rest,
    data: objMap(data, item => {
      if (item.charAt(0) === "{") {
        return JSON.parse(item);
      } else if (parseInt(item)) {
        return parseInt(item);
      } else {
        return item;
      }
    })
  };
}

// a
