// @flowstore

import { userActionCreators as user } from "./user";
import { contentActionCreators as content } from "./content";
import { notificationsActionCreators as notifications } from "./notifications";
import { publiclinkActionCreators as publiclink } from "./publiclink";
import { sharedFoldersActionCreators as sharedFolders } from "./sharedFolders";
import { sharedLinkActionCreators as sharedLinks } from "./sharedLinks";
import { fileRequestsActionCreators as fileRequests } from "./fileRequests";
import { bookmarksActionCreators as bookmarks } from "./bookmarks";
import { businessActionCreators as business } from "./business";
import { audioPlayerActionCreators as audioPlayer } from "./audioPlayer";
import { onboardingActionCreators as onboarding } from "./onboarding";
import { galleryPreviewActionCreators as galleryPreview } from "./galleryPreview";

export default {
  user,
  content,
  notifications,
  publiclink,
  sharedFolders,
  sharedLinks,
  fileRequests,
  bookmarks,
  business,
  audioPlayer,
  onboarding,
  galleryPreview
};
