// @flow

import type { Action } from "../actions/types";
import {
    ONBOARDING_ADD_ITEM,
    ONBOARDING_REMOVE_ITEM,
    ONBOARDING_SET_ACTIVE
} from "../constants/onboarding";
import { getOnboardingSkipName } from "../../../components/HighlightOnboardingComponent/helpers";

export type OnboardingState = {
  queue: Array<any>,
};

const initialState = {
  queue: [],
  active: 0,
};

export function onboardingReducer(
  state: OnboardingState = initialState,
  action: Action
): OnboardingState {
  switch (action.type) {
    case ONBOARDING_ADD_ITEM:
      const onboardingskip = JSON.parse(
          localStorage.getItem(getOnboardingSkipName())
      );

      if (
          (
              Array.isArray(onboardingskip) && onboardingskip.includes(action.id)
          ) ||
          state.queue.includes(action.id)
      ) {
        return state;
      }

      return {
        ...state,
        queue: [...state.queue, action.id]
      };
    case ONBOARDING_REMOVE_ITEM:
      return {
        ...state,
        queue: state.queue.filter(item => item !== action.id)
      };
    case ONBOARDING_SET_ACTIVE:
      return {
        ...state,
        active: action.active
      };
    default:
      return state;
  }
}
