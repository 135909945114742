export const STRIPE_PK = "pk_test_rqxCBCYuPZVO5zmwMeD0yEvk";
export const SAFECHARGE_MERCHANT_ID = "SC_TEST_MERCHANT_ID_8637705980962064725";
export const SAFECHARGE_MERCHANT_SITE_ID = "SC_TEST_MERCHANT_ID_247478";
export const SAFECHARGE_ENV = "SC_ENVIRONMENT_VAR_test";
export const EXTENSION_CHROME = "https://chrome.google.com/webstore/detail/pcloud-save/npamdkabjncnnoaofdjcaipmnccofeem?authuser=1";
export const EXTENSION_FIREFOX = "https://addons.mozilla.org/en-US/firefox/addon/pcloud-save/";
export const EXTENSION_OPERA = "https://chrome.google.com/webstore/detail/pcloud-save/npamdkabjncnnoaofdjcaipmnccofeem";
export const EXTENSION_EDGE = "https://microsoftedge.microsoft.com/addons/detail/pcloud-save/fddpghdlmniinppliebghcdamdiainge";
export const EXTENSION_BRAVE = "https://chrome.google.com/webstore/detail/pcloud-save/npamdkabjncnnoaofdjcaipmnccofeem";

export const PASS_EXTENSION_CHROME = "https://chrome.google.com/webstore/detail/pcloud-pass-password-mana/hldllnfgjbablcfcdcjldbbfopmohnda";
export const PASS_EXTENSION_FIREFOX = "https://addons.mozilla.org/en-US/firefox/addon/pcloud-pass-password-manager/";
export const PASS_EXTENSION_OPERA = " https://chrome.google.com/webstore/detail/pcloud-pass-password-mana/hldllnfgjbablcfcdcjldbbfopmohnda";
export const PASS_EXTENSION_EDGE = "https://microsoftedge.microsoft.com/addons/detail/pcloud-pass-password-ma/ebiiomgikeochbjcbafgeapfmcfgapap";
export const PASS_EXTENSION_SAFARI = " https://apps.apple.com/us/app/pcloud-pass-password-manager/id1607974524";
export const APPSTORE_URL = "https://itunes.apple.com/us/app/pcloud/id692002098";
export const GOOGLEPLAY_URL = "https://play.google.com/store/apps/details?id=com.pcloud.pcloud&referrer=utm_source?website&utm_medium?click";

export const APPSTORE_PASS_URL = "https://apps.apple.com/app/id/1607974524";
export const GOOGLEPLAY_PASS_URL = "https://play.google.com/store/apps/details?id=com.pcloud.pass";

export const BUSINESS_PLAN_ID = 200;
export const BUSINESS_PLAN_2_ID = 201;
export const BUSINESS_PLAN_API_ID = 1;
export const BUSINESS_PLAN_2_API_ID = 2;
export const PREMIUM_PLAN_ID = 1;
export const PREMIUM_PLUS_PLAN_ID = 3;
export const VIVACOM_500GB_PLAN_ID = 7;
export const VIVACOM_1TB_PLAN_ID = 8;
export const VIVACOM_128GB_PLAN_ID = 12;
export const CUSTOM4TB_PLAN_ID = 13;
export const CUSTOM_PLAN_ID = 17;
export const ADD_STORAGE_1COUNT_PLAN_ID = "17_1_A";
export const ADD_STORAGE_4COUNT_PLAN_ID = "17_4_A";
export const ADD_STORAGE_8COUNT_PLAN_ID = "17_8_A";
export const ADD_STORAGE_12COUNT_PLAN_ID = "17_12_A";
export const ADD_STORAGE_20COUNT_PLAN_ID = "17_20_A";
export const CUSTOM_8COUNT_PLAN_ID = "17_8_C";
export const CUSTOM_20COUNT_PLAN_ID = "17_20_C";

export const FAMILY2TB_PLAN_ID = 14;
export const FAMILY_CUSTOM_PLAN_ID = 15;
export const FAMILY_CUSTOM_2COUNT_PLAN_ID = "15_2_C";
export const FAMILY_CUSTOM_5COUNT_PLAN_ID = "15_5_C";
export const FAMILY_ADD_1COUNT_PLAN_ID = "15_1_A";
export const FAMILY_ADD_2COUNT_PLAN_ID = "15_2_A";
export const FAMILY_ADD_5COUNT_PLAN_ID = "15_5_A";

export const CRYPTO_PLAN_ID = 101;
export const EFH_PLAN_ID = 111;
export const RELOCATION_ID = 112;
export const ADDITIONAL_TRAFFIC_ID = 113;

export const MB_PER_STORAGE_COUNT = 500;
export const MB_PER_FAMILY_STORAGE_COUNT = 2048;
export const MB_PER_TRAFFIC_COUNT = 2048;
export const TB_PER_TRAFFIC_COUNT = MB_PER_TRAFFIC_COUNT / 1024;
export const MAX_CUSTOM_TRAFFIC_COUNT = 4;
export const MAX_CUSTOM_STORAGE_COUNT = 32;
export const MAX_FAMILY_TRAFFIC_COUNT = 7;
export const MAX_FAMILY_STORAGE_COUNT = 7;
export const MIN_TRAFFIC_COUNT = 1;
export const MAX_TRAFFIC_COUNT_PER_PERIOD = {
  month: 10,
  year: 10,
  lifetime: 2
};

export const PASS_PREMIUM_PLAN_ID = 130;
export const PASS_FAMILY_PLAN_ID = 131;

export const BUSINESS_PLANS_ID_MAP = {
  [BUSINESS_PLAN_ID]: BUSINESS_PLAN_API_ID,
  [BUSINESS_PLAN_2_ID]: BUSINESS_PLAN_2_API_ID
}

export const BUSINESS_PLANS_ID_MAP_FROM_API = {
  [BUSINESS_PLAN_API_ID]: BUSINESS_PLAN_ID,
  [BUSINESS_PLAN_2_API_ID]: BUSINESS_PLAN_2_ID
}

export const COLORS = {
  cyan: {
    mainColor: "#17bed0",
    border: "none",
    text: "#fff"
  },
  pPassPurple: {
    mainColor: "#746de4",
    border: "none",
    text: "#fff"
  },
  pPassRed: {
    mainColor: "#f73c3c",
    border: "none",
    text: "#fff"
  },
  pPassTransparent: {
    mainColor: "transparent",
    border: "none",
    text: "#444",
    colorOnHover: "#e8e7e8"
  },
  lightCyan: {
    mainColor: "#fff",
    border: "1px solid #aae7ec",
    text: "#17bed0"
  },
  red: {
    mainColor: "#ff4242",
    border: "none",
    text: "#fff"
  },
  red2: {
    mainColor: "#ec654c",
    border: "none",
    text: "#fff"
  },
  redCaution: {
    mainColor: "#f12e2e",
    border: "none",
    text: "#fff"
  },
  pPassLinkButtonColor: {
    mainColor: "transparent",
    border: "none",
    text: "#746de4"
  },

  lightCaution: {
    mainColor: "#fff",
    border: "1px solid #ffb9b9",
    text: "#f12e2e"
  },
  redReset: {
    mainColor: "#fc354c",
    text: "#fff"
  },
  black: {
    mainColor: "#343536",
    border: "none",
    text: "#fff"
  },
  green: {
    mainColor: "#86c600",
    border: "none",
    text: "#fff",
    lightShade: "#fafef5",
    lightShadeBorder: "#dcefb2"
  },
  lightGreen: {
    mainColor: "#ffffff",
    border: "solid 1px rgba(138, 205, 0, 0.75)",
    text: "#8acd00"
  },
  darkGreen: {
    mainColor: "#61C100",
    border: "none",
    text: "#fff"
  },
  gray: {
    mainColor: "#999999",
    border: "none",
    text: "#fff"
  },
  light: {
    background: "#fff",
    border: "1px solid #eeeeee",
    text: "#000"
  },
  lightgray1: {
    mainColor: "#d5d5d5",
    border: "none",
    text: "#fff"
  },
  lightgray2: {
    mainColor: "#f2f2f2",
    border: "1px solid #eeeeee",
    text: "#000"
  },
  lightgray3: {
    mainColor: "#e8e8e8",
    border: "none",
    text: "#444444"
  },
  lightgray4: {
    mainColor: "#e9e9e9",
    border: "none",
    text: "#444444"
  },
  lightgray5: {
    mainColor: "#fff",
    border: "1px solid #969696",
    text: "#212121"
  },
  orange: {
    mainColor: "#ff9326",
    border: "none",
    text: "#fff"
  },
  transparent: {
    mainColor: "transparent",
    border: "none",
    text: "#fff"
  },

  //Italy promo colors
  raspberryRed: {
    mainColor: "#ce2c38",
    border: "none",
    text: "#fff"
  },
  grassGreen: {
    mainColor: "#07b459",
    border: "none",
    text: "#fff"
  },

  //4th July 2020 promo
  skyBlue: {
    mainColor: "#17bed0",
    secondColor: "#18c7da",
    border: "none",
    text: "#fff"
  },
  cherryRed: {
    mainColor: "#cc0c2f",
    secondColor: "#ea0f35",
    border: "none",
    text: "#fff"
  },

  //Canada National Day 2021 promo
  vividRed: {
    mainColor: "#d52b1e",
    border: "none",
    text: "#fff"
  },

  //France National Day 2020 promo
  franceBlue: {
    mainColor: "#04238E",
    border: "none",
    text: "#fff"
  },
  franceRed: {
    mainColor: "#ef3b3a",
    border: "none",
    text: "#fff"
  },

  //Geramny national day 2020 promo
  //Geramny national day 2021 promo
  germanyYeallow: {
    mainColor: "#fdb428",
    border: "none",
    text: "#fff"
  },
  germanyRed: {
    mainColor: "#f43737",
    border: "none",
    text: "#fff"
  },

  //spain 2020 promo
  spainYellow: {
    mainColor: "#ffaa00",
    border: "none",
    text: "#fff"
  },
  spainRed: {
    mainColor: "#eb2a2a",
    border: "none",
    text: "#fff"
  },

  //Halloeen 2020 promo
  //Halloeen 2021 promo
  halloweenOrange: {
    mainColor: "#f87733",
    border: "none",
    text: "#fff"
  },

  //Black Friday 2020 v1
  // mainColor: "#f2bf34"
  // secondColor: "#d3a04e"
  //Black Friday 2021
  blackFridayGold: {
    mainColor: "#dfb257",
    secondColor: "#f3d17b",
    border: "none",
    text: "#000"
  },
  blackFridayRed: {
    mainColor: "#f3144d",
    secondColor: "#cb0a3a",
    border: "none",
    text: "#fff"
  },

  //Cyber Monday 2020 v1
  cyberMondayBlue: {
    mainColor: "#2e38da",
    border: "none",
    text: "#fff"
  },
  cyberMondayPink: {
    mainColor: "#d127b2",
    border: "none",
    text: "#fff"
  },
  cyberMondayPink2: {
    mainColor: "#e533c4",
    border: "none",
    text: "#fff"
  },

  //Black Friday 2020 v2
  blackFridayPink: {
    mainColor: "#d92254",
    border: "none",
    text: "#fff"
  },

  //Cyber Monday 2020 v2
  //mainColor: "#da097f",
  //Cyber Monday 2021
  cyberMondayPink3: {
    mainColor: "#df379e",
    secondColor: "#ff6cc6",
    border: "none",
    text: "#fff"
  },

  //Christmas 2020
  xmasRed: {
    mainColor: "#e30218",
    border: "none",
    text: "#fff"
  },

  //Christmas 2021
  xmas2021Red: {
    mainColor: "#f72f37",
    border: "none",
    text: "#fff"
  },

  //Christmas 2020
  valentineRed: {
    mainColor: "#ed1b1a",
    border: "none",
    text: "#fff"
  },

  //Easter 2021
  easterOrange: {
    mainColor: "#ff5024",
    border: "none",
    text: "#fff"
  },

  //Saint George 2021
  stGeorgeRed: {
    mainColor: "#f23333",
    border: "none",
    text: "#fff"
  },

  //9May
  ninthOfMayRed: {
    mainColor: "#bc3232",
    border: "none",
    text: "#fff"
  },

  //9May
  summerOrange: {
    mainColor: "#ff971c",
    border: "none",
    text: "#fff"
  },

  //4th July
  usaRed: {
    mainColor: "#da1a32",
    border: "none",
    text: "#fff"
  },

  //Back to school
  FranceRed2: {
    mainColor: "#f24c59",
    border: "none",
    text: "#fff"
  },

  //Mexico 2021
  MexicoRed: {
    mainColor: "#f43737",
    border: "none",
    text: "#fff"
  },

  //Canada Thnaks Giving 2021
  CanadaRed: {
    mainColor: "#d52b1e",
    border: "none",
    text: "#fff"
  },

  //Día de los muertos 2021
  MuertosPink: {
    mainColor: "#e21c54",
    border: "none",
    text: "#fff"
  },

  //Chinese New Year 2022
  cny2022red: {
    mainColor: "#e80000",
    border: "none",
    text: "#fff"
  },

  //Valentine's Day 2022
  valentine2022pink: {
    mainColor: "#f03b4d",
    border: "none",
    text: "#fff"
  },

  //Easter 2022
  easter2022pink: {
    mainColor: "#fd4559",
    border: "none",
    text: "#fff"
  },

  //King's day 2022
  kingsDayOrange: {
    mainColor: "#fd9926",
    border: "none",
    text: "#fff"
  },

  //Family day 2022
  family2022green: {
    mainColor: "#96c92b",
    border: "none",
    text: "#fff"
  },

  //Italy Day 2022
  italy2022red: {
    mainColor: "#bf0404",
    border: "none",
    text: "#fff"
  },
  italy2022red2: {
    mainColor: "#e51b24",
    border: "none",
    text: "#fff"
  },
  italy2022white: {
    mainColor: "#fff",
    border: "none",
    text: "#bf0404"
  },

  //4th July 2022
  usa2022red: {
    mainColor: "#df003f",
    border: "none",
    text: "#fff"
  },
  usa2022red2: {
    backgroundColor: "#0c3d90",
    textColor: "#ffffff",
  },
  usa2022white: {
    mainColor: "#fff",
    border: "none",
    text: "#bf0404"
  },

  labor2022: {
    mainColor: "#b31942",
    border: "none",
    text: "#fff",
    backgroundColor: "#0c3d90",
    textColor: "#ffffff",
  },

  moonFestival:{
    mainColor: "#e9b844",
    border: "none",
    text: "#fff",
    backgroundColor: "#432e74",
    textColor: "#ffffff",
  },
  canadaThanksgiving2022:{
    mainColor: "#dc0404",
    border: "none",
    text: "#fff",
    backgroundColor: "#eeb572",
    textColor: "#ffffff",
  },
  halloween2022:{
    mainColor: "#f46c03",
    border: "none",
    text: "#fff",
    backgroundColor: "#22112b",
    textColor: "#ffffff",
  },

  // Summer Promo
  summer2022: {
    mainColor: "#ffa200",
    border: "none",
    text: "#fff"
  },
  // France National Day 2022
  france2022red: {
    mainColor: "#ed2a32",
    border: "none",
    text: "#fff"
  },
  //Germany 2022
  germanyNationalDay:{
    mainColor: "#cf0000",
    border: "none",
    text: "#fff",
    backgroundColor: "#161515",
    textColor: "#ffffff",
  },
  spainNationalDay2022:{
    mainColor: "#fabd00",
    border: "none",
    text: "#fff",
    backgroundColor: "#e0262c",
    textColor: "#ffffff",
  },
  halloween2022v2: {
    mainColor: "#34150d",
    border: "none",
    text: "#fff"
  },
  singlesDay2022:{
    mainColor: "#d9ae13",
    border: "none",
    text: "#fff",
    backgroundColor: "#d9ae13",
    textColor: "#ffffff",
    socialProofSecondColor:"#d5001d",
  },
  diaDeLosMuertos:{
    mainColor: "#aa0e0f",
    border: "none",
    text: "#fff",
    backgroundColor: "#e0262c",
    textColor: "#ffffff",
  },
  //Cyber Monday 2022
  cyberMonday2022: {
    mainColor: "#de369d",
    border: "none",
    text: "#fff"
  },

  //Black Friday 2022
  blackFriday2022: {
    mainColor: "#dfb257",
    secondColor: "#b27b2a",
    border: "none",
    text: "#fff",
    textColor: "#ffffff",
    socialProofSecondColor:"#dfb257",
  },

  //Black Friday 2022 v2
  blackFriday2022v2: {
    mainColor: "#ffc030",
    secondColor: "#b27b2a",
    border: "none",
    text: "#fff",
    textColor: "#ffffff",
    socialProofSecondColor:"#dfb257",
  },

  //Christmas 2022
  xmas2022: {
    mainColor: "#d5221d",
    border: "none",
    text: "#fff",
    textColor: "#000",
    socialProofSecondColor:"#ffb237",
  },

  //Christmas 2022 v2
  xmas2022v2: {
    mainColor: "#d5221d",
    border: "none",
    text: "#fff",
    textColor: "#000",
    socialProofSecondColor:"#ffb237",
  },

  //Chinese New Year 2023
  lunar2023: {
    mainColor: "#bf3846",
    border: "none",
    text: "#fff"
  },

  lunar2023v2: {
    mainColor: "#c90808",
    border: "none",
    text: "#fff",
  },
  //Valentis Day 2023
  valentine2023: {
    mainColor: "#17bed0",
    border: "none",
    text: "#fff"
  },

  //Sacura 2023
  sakura2023: {
    mainColor: "#d82558",
    border: "none",
    text: "#fff"
  },

  // 1 Year Premium
  oneYearPremium: {
    mainColor: "#000",
    border: "none",
    text: "#fff",
    bulletsColor: "#d336c8",
    loadingIconColor: "#d336c8",
    errorIconColor: "#d336c8"
  },

  pCloudPass: {
    mainColor: "#746de4",
    border: "none",
    text: "#fff"
  },

  StGeorge2023: {
    mainColor: "#b12930",
    border: "none",
    text: "#fff"
  },

  KingsDay2023: {
    mainColor: "#ff7300",
    border: "none",
    text: "#fff"
  },

  familyDay2023: {
    mainColor: "#f15843",
    border: "none",
    text: "#fff"
  },

  Italy2023: {
    mainColor: "#ce2b37",
    border: "none",
    text: "#fff"
  },

  Portugal2023: {
    mainColor: "#ee8d41",
    border: "none",
    text: "#fff"
  },

  DragonBoat2023: {
    mainColor: "#f04a08",
    border: "none",
    text: "#fff"
  },

  USA2023: {
    mainColor: "#008cde",
    secondColor: "#2b3aba",
    border: "none",
    text: "#fff"
  },
  USA2023v2: {
    mainColor: "#e93b51",
    border: "none",
    text: "#fff"
  },
  France2023: {
    mainColor: "#f02f40",
    border: "none",
    text: "#fff"
  },
};

export const DefaultScFieldStyles = {
  base: {
    color: '#444444',
    fontWeight: 500,
    fontFamily: 'Roboto, Consolas, Menlo, monospace',
    fontSize: '14px',
    fontSmoothing: 'antialiased',

    '::placeholder': {
      color: '#c5c5c5',
    },
    ':-webkit-autofill': {
      color: '#c5c5c5',
    }
  },
  invalid: {
    color: '#E25950',

    '::placeholder': {
      color: '#c5c5c5',
    }
  },
  empty: { //empty field
    color: '#c5c5c5',
    '::placeholder': {
      color: '#c5c5c5'
    }
  },
  valid: { //when the input is valid
    color: '#444444'
  }
};

export const signToCurrency = {
  "$": "USD",
  "€": "EUR",
  "₤": "GBP"
};

export const currencyMap = {
  'USD': '$',
  'EUR': '€',
  'GBP': '₤'
};

export const OS_NAMES = {
  windows: "Windows",
  mac: "MacOS",
  linux: "Linux",
  android: "Android",
  ios: "iOS"
}

export const BACKUP_DEVICES = {
  mac: "Mac",
  windows: "PC",
  linux: "PC"
}

export const PAYMENT_DISRUPTION_COUNTRIES = ["ru", "by"];
export const ALIPAY_PAYMENT_COUNTRIES = ["au", "at", "be", "bg", "ca", "cn", "cy", "cz", "dk", "ee", "fi", "fr", "de", "gr", "hk", "hu", "ie", "it", "jp", "lv", "lt", "lu", "my", "mt", "nl", "nz", "no", "pt", "ro", "sg", "sk", "si", "es", "se", "ch", "gb", "us"];
export const WECHAT_PAYMENT_COUNTRIES = ["au", "at", "be", "ca", "cn", "dk", "fi", "fr", "de", "hk", "ie", "it", "jp", "lu", "nl", "no", "pt", "sg", "es", "se", "ch", "gb", "us"];

export const PROD_HOSTNAMES = {
  "1": "u.pcloud.com",
  "2": "e.pcloud.com"
};

export const MIN_PASSWORD_STRENGTH = 40;

// Password generator
export const INPUT_RANGE_MIN = 4;
export const INPUT_RANGE_MEDIUM = 16;
export const INPUT_RANGE_MAX = 64;

// TODO mobe to web utils
export const PCLOUD_LINKS = {
  CLOUD_STORAGE_MOBILE: "cloud-storage-mobile-app.html",
  CLOUD_STORAGE_DESKTOP: "download-free-online-cloud-file-storage.html",
  CLOUD_STORAGE_PRICING: "cloud-storage-pricing-plans.html",
  HELP_CENTER: "help/general-help-center/",
  FAMILY_PRICING: "family.html?period=lifetime",
  MANAGE_SUBSCRIPTION: "manage-subscriptions.html",
  OLD_PLATFORM_URL: "https://my.pcloud.com",
}