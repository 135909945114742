// @flow

import store from "./store";
import diffs from "../diffs";
import { abortAll } from "../XhrComponentUtils";
import apiMethod from "../../api/apiMethod";
import { getUserInfo, getTeamInfo } from "../business-utils";

import type { Userinfo } from "../../types/user";
import type { Share } from "../../types/shares";

import type { UserState } from "./reducers/user";
import {
  loadShared,
  unloadShared,
  loadSharedFolders,
  getSharedItems,
  addShareToFolder,
  deleteShareToFolder,
  updateShareToFolder,
  acceptedShareToFolder
} from "./actions/sharedFolders";

let currentToken = "";
let stopListen;
let xhrs = [];

store.subscribe(() => {
  checkUser(currentUserState());
});

checkUser(currentUserState());

function checkUser(userState: UserState) {
  if (userState.token != currentToken) {
    if (currentToken) {
      console.log("stop listen");
      currentToken = "";
      userLogout();
      stopListen();
    }

    if (userState.logged) {
      console.log("go Listen");
      console.log("userState.logged", userState.logged);
      console.log("currentToken", currentToken);
      console.log("userState.token", userState.token);

      currentToken = userState.token;
      userLogin();
      listen();
    }
  }
}

function userLogin() {
  let shares = 0;
  let sharedLinks;
  let sharedFolders = { shares: { incoming: [], outgoing: [] }, requests: [] };
  const currentUser = currentUserinfo();
  const isBusiness = currentUser.business;

  xhrs.push(
    apiMethod("listshares", { auth: currentToken }, ret => {
      shares += ret.shares.incoming.length + ret.shares.outgoing.length;
      sharedFolders.shares.incoming = sharedFolders.shares.incoming.concat(ret.shares.incoming);
      sharedFolders.shares.outgoing = sharedFolders.shares.outgoing.concat(ret.shares.outgoing);
      sharedFolders.requests = sharedFolders.requests.concat(ret.requests);
    })
  );
  if (isBusiness) {
    xhrs.push(
      apiMethod("account_listshares", { auth: currentToken }, ret => {
        shares += ret.shares.incoming.length + ret.shares.outgoing.length;
        sharedFolders.shares.incoming = sharedFolders.shares.incoming.concat(ret.shares.incoming);
        sharedFolders.shares.outgoing = sharedFolders.shares.outgoing.concat(ret.shares.outgoing);
      })
    )
  }

  xhrs.push(
    apiMethod("listpublinks", { auth: currentToken }, ret => {
      sharedLinks = ret.publinks;
    })
  );

  Promise.all(xhrs).then(() => {
    console.log("++++++++++++++++++++");
    console.log("shares", shares);
    console.log("incoming shares", sharedFolders.shares.incoming);
    console.log("outgoing shares", sharedFolders.shares.outgoing);
    console.log("links", sharedLinks);

    store.dispatch(loadShared());
    // store.dispatch(getSharedItems(shares + sharedLinks.length));
    store.dispatch(loadSharedFolders(sharedFolders));
  });
}

function userLogout() {
  abortAll(xhrs);
  store.dispatch(unloadShared());
}

function currentUserinfo(): Userinfo | false {
  const user = currentUserState();
  return user.logged ? user.userinfo : false;
}

function currentUserState(): UserState {
  return store.getState().user;
}

function currentbUsers() {
  return store.getState().business.users;
}

function currentbTeams() {
  return store.getState().business.teams;
}

function parseShare(share: Share) {
  const { fromuserid, folderownerid } = share;

  return Object.assign({}, share, {
    isowner: fromuserid === folderownerid,
    ownerid: folderownerid || HFN.config.user.userid, // folder metas in outgoing shares is missing the userid property
    userobj: getUserInfo(share, currentbUsers()),
    teamobj: getTeamInfo(share, currentbTeams())
  });
}

function listen() {
  const addEvents = [
    "requestshareout",
    // business
    "establishbshareout"
  ];

  const deleteEvents = [
    "declinedshareout",
    "cancelledshareout",
    "removedshareout",
    // business
    "removebshareout"
  ];

  const updateEvents = [
    "modifiedshareout",
    // business
    "modifybshareout"
  ];

  stopListen = diffs.listen(currentToken, events => {
    const currentAddEvents = events.filter(item => addEvents.indexOf(item.event) !== -1);
    const currentDeleteEvents = events.filter(item => deleteEvents.indexOf(item.event) !== -1);
    const currentUpdateEvents = events.filter(item => updateEvents.indexOf(item.event) !== -1);
    const acceptedShares = events.filter(ev => ev.event === "acceptedshareout");

    if (currentAddEvents.length) {
      currentAddEvents.forEach(element => {
        console.log("SHARES>>>>> currentAddEvent", element);
        const share = parseShare(element.share);

        store.dispatch(addShareToFolder(share));
      });
    }

    if (currentDeleteEvents.length) {
      currentDeleteEvents.forEach(element => {
        console.log("SHARES>>>>> currentDeleteEvent", element);
        const share = parseShare(element.share);

        store.dispatch(deleteShareToFolder(share));
      });
    }

    if (currentUpdateEvents.length) {
      currentUpdateEvents.forEach(element => {
        console.log("SHARES>>>>> currentUpdateEvent", element);
        const share = parseShare(element.share);

        store.dispatch(updateShareToFolder(share));
      });
    }

    if (acceptedShares.length) {
      acceptedShares.forEach(element => {
        console.log("SHARES>>>>> acceptedShare", element);
        const share = parseShare(element.share);

        store.dispatch(acceptedShareToFolder(share));
      });
    }
  });
}
