export const getOnboardingSkipName = () => {
    const userId = HFN.config.user.userid ?? 0;
    const locationId = HFN.apiConfig.locationid;

    if (userId === 0) {
        return false;
    }

    return "onboardingskip-" + userId + "-" + locationId;
};
