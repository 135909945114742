// @flow
import {
  LOAD_SHARED,
  UNLOAD_SHARED,
  UPDATE_SHARE_TO_FOLDER,
  LOAD_SHARED_FOLDERS,
  LOAD_FOLDER_SHARES,
  CLEAR_FOLDER_SHARES,
  GET_SHARED_ITEMS,
  ADD_SHARE_TO_FOLDER,
  DELETE_SHARE_TO_FOLDER,
  ACCEPTED_SHARE_TO_FOLDER,
  GET_SHARE_MESSAGE,
  GET_SHARE_PERMISSION,
  GET_SHARE_FOLDER_NAME
} from "../constants/sharedFolders";
import type { Action } from "../actions/types";

export type SharedFoldersState = {
  loaded: boolean,
  numberOfSharedItems?: number,
  sharedFolders: any,
  folderShares: Array<any>,
  shareMessage: string,
  sharePermissionId: number,
  shareFolderName: string
};

const initialState: SharedFoldersState = {
  loaded: false,
  sharedFolders: { shares: [], requests: [] },
  numberOfSharedItems: 0,
  folderShares: [],
  shareMessage: "",
  sharePermissionId: 0,
  shareFolderName: ""
};

const deleteShare = (state: SharedFoldersState, share) => {
  const { folderShares } = state;
  const { shareid, sharerequestid } = share;

  if (shareid) {
    return folderShares.filter(share => share.shareid !== shareid);
  } else if (sharerequestid) {
    return folderShares.filter(share => share.sharerequestid !== sharerequestid);
  } else {
    return folderShares;
  }
};

const updateShare = (state, share) => {
  const { folderShares } = state;
  const { shareid, sharerequestid } = share;

  if (shareid) {
    const filteredShares = folderShares.filter(share => share.shareid !== shareid);
    return [...filteredShares, share];
  } else if (sharerequestid) {
    const filteredShares = folderShares.filter(share => share.sharerequestid !== sharerequestid);
    return [...filteredShares, share];
  } else {
    return folderShares;
  }
};

const acceptedShare = (state, share) => {
  const { folderShares } = state;
  const { sharerequestid } = share;

  if (sharerequestid) {
    const filteredShares = folderShares.filter(share => share.sharerequestid !== sharerequestid);
    delete share.sharerequestid;
    return [...filteredShares, share];
  } else {
    return folderShares;
  }
};

export function sharedFoldersReducer(state: SharedFoldersState = initialState, action: Action): SharedFoldersState {
  const { type, payload } = action;

  switch (type) {
    case LOAD_SHARED:
      return { ...state, loaded: true };
    case UNLOAD_SHARED:
      return initialState;
    case LOAD_SHARED_FOLDERS:
      return { ...state, sharedFolders: payload };
    // invite to folder modal
    case LOAD_FOLDER_SHARES:
      return { ...state, folderShares: payload };
    case CLEAR_FOLDER_SHARES:
      return { ...state, folderShares: [] };
    case GET_SHARED_ITEMS:
      return { ...state, numberOfSharedItems: payload };
    case ADD_SHARE_TO_FOLDER:
      return { ...state, folderShares: [...state.folderShares, payload] };
    case DELETE_SHARE_TO_FOLDER:
      return { ...state, folderShares: deleteShare(state, payload) };
    case UPDATE_SHARE_TO_FOLDER:
      return { ...state, folderShares: updateShare(state, payload) };
    case ACCEPTED_SHARE_TO_FOLDER:
      return { ...state, folderShares: acceptedShare(state, payload) };
    case GET_SHARE_MESSAGE:
      return { ...state, shareMessage: payload };
    case GET_SHARE_PERMISSION:
      return { ...state, sharePermissionId: payload };
    case GET_SHARE_FOLDER_NAME:
      return { ...state, shareFolderName: payload };
    default:
      return state;
  }
}
