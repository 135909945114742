import React, { useState } from "react";
import styled from "styled-components";

import InputText from './InputText';

const InputPassword = ({ revealPassword, value, InputComponent = InputText, ...rest }) => {
  if (revealPassword) {
    const [revealPassword, setRevealPassword] = useState(false);

    const onPassRevealToggle = () => {
      setRevealPassword((prev) => {
        return !prev;
      })
    }

    return (
      <InputWrapper>
        <RelativeWrapper>
          <InputComponent {...rest} value={value} type={revealPassword ? "text" : "password"}/>
          <IconWrapper show={!!value}>
            {revealPassword ? <i onClick={() => onPassRevealToggle()} className="fa-solid fa-eye" /> : <i className="fa-solid fa-eye-slash" onClick={() => onPassRevealToggle()}/> }
          </IconWrapper>
        </RelativeWrapper>
      </InputWrapper>
    )
  };

  return <InputComponent value={value} {...rest} type="password" />
};

export default InputPassword;

const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const RelativeWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const IconWrapper = styled.div`
  position: absolute;
  right: 14px;
  top: 50%;
  transform: translateY(-50%);
  color: var(--border-primary);
  font-size: var(--font-size-16);
  display: inline-block;
  min-width: 20px;
  opacity: ${({show}) => show ? 1 : 0};
  visibility: ${({show}) => show ? "visible" : "hidden"};;
  transition: all 200ms ease-in-out;
  cursor: pointer;

  &:hover {
    color: var(--color-grey-800)
  }
`;
